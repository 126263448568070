
.select-icons {
    width: 100%;
    height: 91vh;
    margin: 0;
    background: #fff;
    position: fixed;
    bottom: 0;
}
.select-icons.intro-page {
    height: 95vh;
}
.selector-title {
    margin: 28px 25px 0;
    box-sizing: border-box;
}
.select-icons h4 {
    font-family: PTSerif-Regular;
    font-size: 26px;
    line-height: 30px;
    color: #2F2F2F;
    font-weight: normal;
    margin: 0 0 16.95px;
}
.select-item .item-outer {
    border: 1px solid #979797;
    width: 150px;
    height: 150px;
    border-radius: 10px;
    padding: 10px 13px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
}

.item-image-space .item-title {
    align-items: flex-end;
}
.select-item .item-outer>img , .item-image-space>img {
    padding-top: 21px;
    object-fit: cover;
    
}
.item-title p {
    margin: 0;
    font-size: 14px;
    color: #505050;
    font-weight: 700;
}
.item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select-item {
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 85%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 8px;
}
.load-existing h4 {
    padding: 0px 25px 0;
    margin: 0 0 31px;
    color: #2F2F2F;
    font-weight: normal;
}
.load-existing {
    margin: 50px auto 0;
    background: #E5EFF0;
    padding: 35px 0 65px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
}
.select-plumbing .selector-title h4 {
    margin-bottom: 30px;
}
.select-plumbing .item-outer>img {
    padding-top: 17px;
}
.select-plumbing .selector-title p {
    font-size: 16px;
    font-family: 'Conv_ProximaNova-Regular';
    line-height: 18px;
    color: #2F2F2F;
    margin: 0 0 37px;
}
.book-free-cons button {
    display: block;width:100%;box-sizing: border-box;
}
.load-project.select-plumbing .selector-title p {
    margin: 0 0 28px;
    text-align: left;
}
.book-free-cons {
    padding: 0 25px;
}
.book-free-cons input {
    border: 1px solid #DCDCDC;
    border-radius: 3px;
    height: 45px;
    padding: 13px 17px;
	display: block;width:100%;box-sizing: border-box;
}
.book-free-cons .consult {
    height: 38px;
    font-size: 14px !important;
    border-radius: 3px !important;
    background: #f5875f;
    border: 1px solid #f5875f;
    color: #fff;
    padding: 7px 11px;
    text-transform: uppercase;
    top: 0;
    line-height: 19px;
    margin: 12px auto 0;
    font-weight: 400 !important;
    right: unset;
}
.saved-project-list {
    padding: 0 10px;
    display: flex;
    overflow-x: scroll;
    gap: 10px;
}

.saved-list-box {
    background: #fff;
    border-radius: 15px;
    max-width: 120px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.saved-project-list a {
    display: flex;
    flex-shrink: 0;
}
.saved-list-box img {
    width: 100%;
    max-width: 100%;
    padding-top: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.saved-project {
    height: 300px;
    background: #E5EFF0;
}
.saved-list-box p {
    margin: 0;
    padding: 12px 13px;
    line-height: 19px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    color: #2f2f2f;
}
.load-project .saved-project {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.future-shower .item-outer {
    border-color: #E5EFF0;
}
.help-screen .headerbg, .help-screen .bottomfixed {
    background: transparent;
}
.help-screen .headerbg .logo {
    opacity: 0%; 
}
.help-screen .help-info-header {
    color: #fff;
    font-size: 16px;
    position: fixed;
}
.help-screen .help-info-header.back img {
    max-width: 100% !important;
}
.help-screen .help-info-header.back {
    margin-top: -15px;
    width: 100%;
    left: 30px;
}
.help-screen .help-info-header.back.fr, .help-screen .help-info-header.back.es {
    left: 45px;
}
.help-screen .help-info-header.back p {
    position: relative;
    left: -25px;
}
.help-screen .help-info-header.back.fr p {
    position: relative;
    left: -45px;
}
.help-screen .help-info-header.book {
    margin-top: -5px;
    right: -120px;
}
.help-screen .help-info-header.book p {
    position: relative;
    top: -32px;
    left: -108px;
    width: 50%;
}
.help-screen .help-info-header.book.fr {
    margin-top: -7px;
    right: -215px;
}
.help-screen .help-info-header.book.es p {
    position: relative;
    top: -32px;
    left: -92px;
    width: 50%;
}
.help-screen .help-info-bottom {
    margin-left: -10px;
    color: #fff;
    position: fixed;
    bottom: 30px;
    font-size: 16px;
}
.help-screen .help-info-bottom.start-new {
    margin-left: -3px;
    bottom: 50px;
}
.help-screen .help-info-bottom.save.fr {
    margin-left: -33px;
    bottom: 50px;
}
.help-screen .help-info-bottom.save.es {
    margin-left: -20px;
    bottom: 50px;
}
.help-screen .help-info-bottom.share.fr {
    margin-left: -17px;
}
.help-screen .help-info-bottom.share.es {
    margin-left: -20px;
}
.help-screen .help-info-bottom.undo {
    margin-left: 18px;
    bottom: 50px;
}
.help-screen .help-info-bottom.start-new p, .help-screen .help-info-bottom.undo p {
    margin-bottom: 5px;
}
.help-screen .help-info-bottom.start-new.fr p {
    width: 50%;
}
.help-screen .help-info-bottom.undo.fr p {
    width: 60%;
}
.help-screen .fixedbck .help-info-bottom {
    bottom: 65px;
}
.help-screen .fixedbck .help-info-bottom.fr, .help-screen .fixedbck .help-info-bottom.es {
    line-height: normal;
    bottom: 80px;
    margin-left: -25px;
}
.help-screen {
    background: rgb(0 0 0 / 60%);
    padding: 14px 13px 14px 24px;
    box-sizing: border-box;
    margin: 0;
    height: 100% !important;
    overflow-x: clip;
    position: fixed;
    z-index: 999999;
}
.book-now-btn {
    text-align: right;
    margin-bottom: 70px;
}
.get-started button.started {
    background: #F5875F;
    border-radius: 5px;
    width: 255px;
    height: 49px;
    color: #ffff;
    font-size: 24px;
    text-transform: uppercase;
    border: 0;
}
.get-started {
    position: fixed;
    top: 43%;
    transform: translate(-50% , 40%);
    left: 50%;
}
.follow-steps-mobile p {
    margin: 0;
    color: #006269;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.follow-steps-mobile {
    background: #E5EFF0;
    padding: 28px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}
.intro-caption {
    padding: 0 25px;
    box-sizing: border-box;
}
.intro-caption p {
    margin: 0;
    font-size: 16px;
}
.load-existing-item .item-outer {
    background: #fff;
}
/**Slider-css**/
.intro-image {
    text-align: center;
    box-sizing: border-box;
    display: flex;
    position: relative;
    width: 100%;
    height: 200px;
    align-items: center;
}
.center-slider li::marker {
    color: transparent;
}

.center-slider li {
    width: 10px;
    height: 10px;
    background: #BEBEBE;
    border-radius: 50%;
    margin-right: 16px;
}

.center-slider {
    margin: 72px 0 42px;
}
.intro-slider ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}
.intro-image .slide1 img, .intro-image .slide3 img {
    width: 210px;
    height: 161px;
    margin: auto 10px;
    object-fit: cover;
}
.intro-image .slide2 img {
    width: 246px;
    height: 189px;
}

.intro-image .slide1 {
    position: absolute;
    left: -33%;
}

.intro-image .slide2 {position: absolute;left: 50%;transform: translateX(-50%);
    transition: 0.5s;}

.intro-image .slide3 {
    position: absolute;
    left: 74%;
}
.center-slider .slick-dots li {
    background: transparent;
}
.center-slider .slick-dots li button:before {
    font-size: 9.6px;
    color: #bebebe !important;
}
.center-slider .slick-slide>div {
    transform: scale(.95);
    transition: transform .3s cubic-bezier(.4, 0, .2, 1);
    opacity: 0.4;
  }
  
  .center-slider .slick-center>div {
    transform: scale(1.05);
    opacity: 1;
  }
  
  .center-slider .slider__item>img {
    width: 100%;
    height: auto;
  }
.load-project .selector-title h4 {
    margin-bottom: 17px;
}
.saved-project.noData{display:none;}
.center-slider .slick-slide img {
    margin:auto;
}
