/*---------------css for wall in -------------1600 to 2200 screen size*/

@media (min-width: 6000px) {
	.fauctimage {
		bottom: 110px;
		right: -260px;
	}

	.soapimage {
		bottom: 170px;
		right: -185px;
	}

	.shelveimage {
		bottom: 200px;
		right: -260px;
	}

	.bathtub {
		right: -30px;
	}

	.wallimage {
		bottom: -10px;
		right: -28px;
	}

	.sideBarMain {
		bottom: 42%;
		right: 27.5%;
	}

	.sideBarMainHoriLow {
		bottom: 37% !important;
	}

	.sideBarMainHoriMid {
		bottom: 40% !important;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertLow {
		bottom: 43% !important;
	}

	.sideBarMainVertMid {
		bottom: 46% !important;
	}

	.sideBarMainVertHigh {
		bottom: 50% !important;
	}

	.doorimage {
		bottom: -100px;
		right: -229px;
		transform: scale(1.1655, 1.35);
	}

	.rodimage {
		right: 30px;
	}
}

@media only screen and (min-width: 4000px) and (max-width: 6000px) {
	.doorimage {
		bottom: 75px !important;
		transform: scale(1.045, 1.195) !important;
	}

	.fauctimage {
		bottom: 30px;
		right: 30px;
	}

	.soapimage {
		bottom: 80px !important;
	}

	.sideBarMain {
		bottom: 43% !important;
	}

	.sideBarMainHoriLow {
		bottom: 37% !important;
	}

	.sideBarMainHoriMid {
		bottom: 40% !important;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertLow {
		bottom: 43% !important;
	}

	.sideBarMainVertMid {
		bottom: 46% !important;
	}

	.sideBarMainVertHigh {
		bottom: 50% !important;
	}
}

@media only screen and (min-width: 2200px) and (max-width: 6000px) {
	.imagesborder img {
		max-width: 50%;
		object-fit: contain;
		object-position: center;
		width: 100%;
	}

	#accessDiv .col-sm-12.colors.desktopMobile .bordercolor .imagesborder {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#accessDiv .col-sm-12.colors.desktopMobile .bordercolor .imagesborder img {
		object-fit: contain;
		max-width: 55%;
		object-position: center;
	}

	.doorimage {
		bottom: 35px;
		transform: scale(1.045, 1.175);
	}

	.sideBarMainHoriMid {
		bottom: 35%;
	}

	.sideBarMainVertLow {
		bottom: 38%;
	}

	.sideBarMainVertHigh {
		bottom: 44%;
	}

	.rodimage {
		right: 20px;
	}

	.sideBarMainVertMid {
		bottom: 42%;
		position: fixed;
		right: 27%;
		width: 6%;
	}
}

@media (min-width: 1400px) and (max-width: 1670px) {
	.saveicons ul.border li a {
		font-size: 12px;
	}

	.bathtub {
		bottom: -68px;
		height: 100%;
		position: fixed;
		right: -9.5px;
		transform: scaleY(1.14);
		width: 61%;
	}

	.bathtub img {
		max-width: 100%;
		object-fit: cover;
		object-position: center center;
		width: 100%;
		/*transform: scale(0.99, 1.15);*/
	}

	.wallimage img {
		max-width: 100%;
		object-fit: cover;
		object-position: center center;
		width: 100%;
		transform: scaleY(1.125);
		position: relative;
		left: -3px;
		height: auto;
	}

	.wallimage {
		bottom: -54px;
		height: 100%;
		position: fixed;
		right: -11px;
		transform: scaleY(1.085);
		width: 61%;
	}

	.fauctimage {
		bottom: 45px;
	}

	.rodimage {
		bottom: -91px;
		height: 100%;
		transform: scaleY(1.1);
		-webkit-transform: scaleY(1.1);
	}

	div#scrollBath span.imagesborder {
		padding: 5px 0px 20px;
	}

	.shelveimage {
		bottom: 70px;
	}

	.soapimage {
		bottom: -75px;
		height: 100%;
	}

	.sideBarMain {
		bottom: 42%;
	}

	.sideBarMainVertLow {
		bottom: 41%;
	}

	.RodSummary img.Summary_summaryRodImg__qvvdb {
		max-width: 50% !important;
	}

	.sideBarMainHoriMid {
		bottom: 39%;
	}

	.sideBarMainHoriHigh {
		bottom: 45%;
	}

	.sideBarMainVertMid {
		bottom: 46%;
	}

	.sideBarMainVertHigh {
		bottom: 51%;
	}

	.sideBarMainHoriLow {
		bottom: 35%;
	}

	.doorimage {
		bottom: -70px;
		-webkit-transform: scaleY(1.305);
		transform: scaleY(1.305);
		z-index: 999999;
	}

	.AccesoryMain div#accessDiv .imagesfull .col-sm-4 {
		width: 25%;
		padding: 0 !important;
	}

	.AccesoryMain div#accessDiv .imagesfull .col-sm-4 .imagesborder {
		height: 100px;
	}

	.accessorySummary table.innergrid tr:nth-child(2) td .images img {
		bottom: 180px;
	}

	.faucetSummary table.innergrid tr:first-child td .images img {
		margin: 0;
		width: 60%;
	}

	td.product-name span {
		font-weight: 700 !important;
	}

	#tablescrollOd table td:nth-child(3) .colorboxes {
		position: relative;
		top: 10px;
	}

	.bottomfixed {
		bottom: -80px;
	}

	.load-project-inner .load-email-field {
		width: 406px;
	}
}

@media (min-width: 1330px) and (max-width: 1399px) {
	.doorimage {
		bottom: -7px;
		height: 100%;
		right: 0px;
		-webkit-transform: scaleY(1.085);
		transform: scaleY(1.085);
		z-index: 999999;
	}

	.accessorySummary span.images.img-align {
		text-align: left;
	}

	.faucetSummary span#faucetHeight img {
		margin: auto;
	}

	img.Summary_summaryRodImg__qvvdb {
		max-width: 70% !important;
		margin: auto;
		display: block;
	}

	.bathtub {
		bottom: -4px;
		right: -6px;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	.wallimage {
		bottom: -5px;
		position: fixed;
		right: -7px;
		width: 61%;
	}

	.fauctimage {
		bottom: 2px;
		position: fixed;
		right: 6px;
		width: 58%;
		z-index: 999999;
	}

	.shelveimage {
		bottom: 0;
		position: fixed;
		right: -11px;
		width: 60%;
	}

	.rodimage {
		bottom: 0;
		position: fixed;
		right: 6px;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		width: 59%;
		z-index: 999999;
	}

	img.Summary_imgFaucet__ro50P {
		margin: 0;
	}

	img#vertBar {
		bottom: 30px !important;
		position: relative;
	}

	img#horiBar {
		bottom: 20px !important;
		position: relative;
	}

	img#horiBar {
		bottom: 30px !important;
		position: relative;
	}

	#rightBar {
		position: relative;
		top: -20px;
		right: 6px;
	}

	img#leftBar {
		position: relative;
		right: -10px;
		bottom: 20px;
	}

	img#vertBar {
		bottom: 43px !important;
		position: relative;
	}

	.saveicons ul li {
		margin: 5px auto;
		text-align: left;
		width: 50%;
	}
}
@media (max-width: 1366px) {
	.pre-config-main2.plumbing-width-lg h2 {	
		font-size: 24px;	
	}	
}
@media (max-width: 1280px) {
	.saveicons ul li {
		margin: 5px auto;
		text-align: left;
		width: 50%;
	}

	.accessorySummary span.images.img-align {
		text-align: left;
	}

	img.Summary_summaryRodImg__qvvdb {
		max-width: 70% !important;
		margin: auto;
		display: block;
	}

	.doorimage {
		bottom: -43px;
		right: 2px;
		-webkit-transform: scaleY(1.205);
		transform: scaleY(1.205);
		z-index: 999999;
	}

	.rodimage {
		bottom: 33px;
		transform: scaleY(1.125);
	}

	.bathtub {
		/* right: -7px;
		bottom: 30px; */
		transform: scaleY(1);
	}

	.wallimage {
		right: -6px;
		transform: scaleY(1.05);
		bottom: 20px;
	}

	.fauctimage {
		bottom: 22px;
	}

	.sideBarMain {
		position: fixed;
		bottom: 43%;
	}

	.soapimage {
		bottom: 3%;
	}

	.sideBarMainHoriLow {
		bottom: 36%;
	}

	.sideBarMainHoriMid {
		bottom: 39%;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertLow {
		bottom: 42%;
	}

	.sideBarMainVertMid {
		bottom: 45%;
	}

	.sideBarMainVertHigh {
		bottom: 50%;
	}
	.pre-config-main2.plumbing-width-lg h2 {
		font-size: 24px !important;
	}
	.navigation ul li a {
		font-size: 14px;
		padding: 14px 14px 14px 14px;
	}
}

@media (max-width: 1200px) {
	.navigation ul li a {
		padding: 12px 12px 12px 12px;
	}

	.middimages,
	.padding {
		display: flex;
		flex-wrap: wrap;
	}

	.soapimage {
		bottom: 5%;
	}

	/* .bathtub {
		bottom: 35px;
	} */

	.wallimage {
		transform: scaleY(1.25);
		bottom: 49px;
	}

	.fauctimage {
		bottom: 42px;
	}

	.shelveimage {
		bottom: 50px;
	}

	.sideBarMainHoriLow {
		bottom: 34%;
	}

	.sideBarMainVertMid {
		bottom: 42%;
	}

	.sideBarMainVertHigh {
		bottom: 45%;
	}

	.sideBarMainVertLow {
		bottom: 39%;
	}

	.doorimage {
		bottom: -72px;
		right: 0;
		transform: scaleY(1.315);
		z-index: 999999;
	}

	.rodimage {
		bottom: 45px;
		transform: scaleY(1.155);
		-webkit-transform: scaleY(1.155);
	}
}

@media (min-width: 995px) and (max-width: 1025px) {
	.wallimage {
		bottom: 122px;
		right: -6px;
		-webkit-transform: scaleY(1.75);
		transform: scaleY(1.75);
	}

	.bathtub {
		bottom: unset;
		/* transform: scale(1, 1.3);
		-webkit-transform: scale(1, 1.3); */
	}

	.fauctimage {
		bottom: 85px;
		transform: scale(1.25);
		right: 30px;
	}

	div#faucetDiv .col-sm-4 {
		padding: 0 !important;
		display: flex !important;
		height: 190px;
	}

	.shelveimage {
		bottom: 130px;
	}

	.sideBarMain {
		bottom: 39%;
	}

	.sideBarMainVertLow {
		bottom: 40%;
	}

	.sideBarMainVertMid {
		bottom: 43%;
	}

	.sideBarMainVertHigh {
		bottom: 48%;
	}

	.soapimage {
		bottom: 75px;
	}

	.sideBarMainHoriLow {
		bottom: 36%;
	}

	.orientationsBars .col-sm-3 {
		padding: 0 5px;
	}

	.addbutton {
		text-align: center;
		z-index: 9;
		/* width: 100%; */
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.bottomPlacement .col-sm-12.colors span {
		float: none;
		margin: 0;
	}

	div#scrollBath .col-sm-4 {
		padding: 0;
	}

	.rodimage {
		bottom: 86px;
		right: 5px;
		transform: scaleY(1.355);
		-webkit-transform: scaleY(1.355);
	}

	.doorimage {
		bottom: -145px;
		right: 0;
		transform: scaleY(1.54);
		z-index: 999999;
	}

	.accessorySummary span.images.img-align {
		text-align: left;
	}
	

}

@media (max-width: 1600px) {
	.tittle {
		font-size: 13px;
		line-height: 16px;
	}
    .design-container {
        top: 100px;
    }
	.pre-config-main2.plumbing-width-lg h2 {
        font-size: 26px;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
	.bathtub {
		bottom: 0px;
		height: 100%;
		position: fixed;
		right: -9.5px;
		-webkit-transform: scaleY(1.115);
		transform: scaleY(1.06);
		width: 61%;
	}

	.wallimage img {
		left: -3px;
		position: relative;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	.fauctimage {
		bottom: 15px;
	}

	.shelveimage {
		bottom: 0px;
	}

	.soapimage {
		bottom: 0;
		height: 100%;
	}

	.doorimage {
		bottom: -5px;
		-webkit-transform: scaleY(1.305);
		transform: scaleY(1.11);
		z-index: 999999;
	}

	.rodimage {
		bottom: -20px;
		height: 100%;
		transform: scaleY(1.05);
		-webkit-transform: scaleY(1.05);
	}

	.wallimage {
		bottom: -11px;
		height: 100%;
		position: fixed;
		right: -11px;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		width: 61%;
	}
}

@media only screen and (min-width: 1680px) and (max-width: 2000px) {
	.bathtub {
		bottom: -3px;
		position: fixed;
		right: -22px;
		width: 62.5%;
		transform: scaleX(0.96);
	}

	.wallimage {
		bottom: -7px;
		position: fixed;
		right: -23px;
		width: 62.5%;
		transform: scaleX(0.975);
	}

	#accessDiv .col-sm-12.colors.desktopMobile .bordercolor .imagesborder {
		height: 120px;
		padding: 20px;
	}

	.MainBar .col-sm-3 img {
		object-fit: contain !important;
		height: 100% !important;
	}

	.MainBar .verticalBar.col-sm-3 img {
		transform: rotate(245deg) scale(0.9);
	}

	.sideBarMain {
		bottom: 41%;
		right: 27%;
	}

	.sideBarMainHoriMid,
	.sideBarMainVertLow {
		bottom: 41%;
	}

	.sideBarMainHoriLow {
		bottom: 36%;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertMid {
		bottom: 44%;
	}

	.sideBarMainVertHigh {
		bottom: 48%;
	}

	img.Summary_imgFaucet__DvokG {
		margin: 0 !important;
	}

	span.images.img-align {
		text-align: center;
	}

	.RodSummary img.Summary_summaryRodImg__qvvdb img.Summary_summaryRodImg__qvvdb {
		width: 140px !important;
	}

	.doorimage {
		bottom: 12px;
		height: 100%;
		position: fixed;
		right: -10px;
		transform: scale(1.025, 1.105);
		width: 61%;
		z-index: 9999999;
	}

	.rodimage {
		right: 8px;
		transform: scaleY(1.075);
		bottom: 22px;
	}

	img.Summary_imgFaucet__ro50P {
		margin: 0px 0px 1px 0 !important;
		width: 60%;
	}

	.accessorySummary table tr:first-child td span#faucetHeight img {
		left: -155px;
		/* transform: scale(3.125); */
		top: 70px;
	}

	.accessorySummary table tr:nth-child(2) td span.images img {
		bottom: 205px;
		/* transform: scale(5.5); */
	}

	.accessorySummary table tr:last-child td span.images img {
		/* transform: scale(.4); */
		top: 0;
	}

	#tablescrollOd table td:nth-child(3) .colorboxes {
		position: relative;
		top: 10px;
	}

	.shelveimage {
		right: 0px;
	}

	.imagesborder img {
		max-width: 100%;
		object-fit: contain;
		height: 100%;
		width: 100%;
	}

	.fauctimage {
		right: 0px;
		bottom: 20px;
	}

	.soapimage {
		right: 0;
		bottom: 20px;
	}

	.sideBarMainVertLow {
		bottom: 43%;
	}

	.sideBarMainVertHigh {
		bottom: 51%;
	}

	.sideBarMainVertMid {
		bottom: 46%;
	}

	.accessorySummary table span.images img.Summary_summarySoapImg__HkY6v {
		bottom: 255px;
	}
}

@media only screen and (min-width: 1900px) and (max-width: 2000px) {
	.bathtub {
		right: -22px;
		bottom: 5px;
	}

	.wallimage {
		bottom: 25px;
		right: -23px;
		-webkit-transform: scale(0.975, 1.105);
		transform: scale(0.975, 1.105);
	}

	.doorimage {
		bottom: -16px;
		transform: scale(1.025, 1.195);
	}

	#faucetHeight img#summaryFaucet {
		width: 100%;
		max-width: 70%;
		margin: auto;
	}

	.accessorySummary img.Summary_summarySideBarImg__50MWW {
		padding-left: 30px !important;
		width: 80% !important;
	}

	.rodimage {
		bottom: 42px;
	}
}

@media only screen and (min-width: 2000px) and (max-width: 2500px) {
	.sideBarMain {
		bottom: 39%;
	}

	.sideBarMainHoriMid {
		bottom: 36%;
	}

	.sideBarMainHoriLow {
		bottom: 33%;
	}

	.sideBarMainHoriHigh {
		bottom: 40%;
	}

	.sideBarMainVertLow {
		bottom: 40%;
	}

	.sideBarMainVertMid {
		bottom: 42%;
	}

	.sideBarMainVertHigh {
		bottom: 44%;
	}

	.doorimage {
		bottom: 14px;
		transform: scale(1.035, 1.135);
	}

	.rodimage {
		right: 13px;
	}

	.orientationsBars img,
	.MainBar .verticalBar.col-sm-3 img {
		height: 60%;
	}
}

@media only screen and (min-width: 3000px) and (max-width: 4000px) {
	.fauctimage {
		bottom: -2px;
		right: 10px;
	}

	.soapimage {
		bottom: 30px;
	}

	.sideBarMain {
		bottom: 41%;
	}

	.sideBarMainHoriLow {
		bottom: 37%;
	}

	.sideBarMainHoriMid {
		bottom: 40%;
	}

	.sideBarMainHoriHigh {
		bottom: 43%;
	}

	.sideBarMainVertLow {
		bottom: 43%;
	}

	.sideBarMainVertMid {
		bottom: 46%;
	}

	.sideBarMainVertHigh {
		bottom: 50%;
	}
}

@media (min-width: 2000px) {
    .leftmenu.DesktopView .imagesfull .col-sm-4 {
		width: 25%;
	}
}




@media only screen and (min-width: 200px) and (max-width: 1081px) {
	
	
	.navigation ul li a {
		font-size: 13px;
	}
	
	.logo {
		left: 0;
		position: relative;
		right: 0;
		text-align: center;
		top: 0;
	}
	.logo img {
		max-width: 19%;
		height: 32px;
		object-fit: contain;
	}
	#desktop {
		display: none;
	}

	#Mobile {
		display: block;
		overflow-x: hidden;
	}

	.col-sm-4.leftmenu {
		padding: 0 10px;
	}

	div#tablescrollOd table img#summaryBath {
		top: 0;
	}

	.col-sm-5.addbutton {
		width: 45%;
		text-align: right;
		justify-content: flex-end;
	}

	.col-sm-7.headingleft {
		width: 50%;
		float: left;
	}

	.SummaryActiveMobile {
		display: none;
	}

	.mobileBathMenu.active {
		display: block;
	}

	.mobileBathMenu {
		display: none;
	}

	.mobileFaucetMenu {
		display: none;
	}

	.mobileFaucetMenu.active {
		display: block;
	}

	.mobileWallMenu.active {
		display: block;
	}

	.mobileWallMenu {
		display: none;
	}

	.mobileAccessMenu {
		display: none;
	}

	.mobileAccessMenu.active {
		display: block;
	}

	.mobileDoorRodMenu {
		display: none;
	}

	.mobileDoorRodMenu.active {
		display: block;
	}

	.mobileSummary {
		display: none;
	}

	.mobileSummary.active {
		display: block;
		padding-bottom: 50px;
		float: left;
	}

	.maninav {
		width: 100%;
		float: left;
		margin: auto;
		padding: 0 !important;
		/* background-color: #333; */
		/* overflow: auto; */
		white-space: nowrap;
	}

	.maninav ul {
		width: 100%;
	}

	.maninav ul li {
		display: table-cell;
		float: none;
		vertical-align: middle;
	}

	.mainimages {
		position: relative;
		bottom: 0;
		top: 0;
		right: 0;
		height: 100%;
		max-width: 100%;
	}

	.col-sm-12.leftmenu {
		float: left;
		width: 100%;
		margin: auto;
		height: 100vh;
		overflow: auto;
		margin-bottom: 60px;
		margin-top: 0px;
	}
	.col-sm-12.leftmenu .middle_scroll {
		bottom: 0px;
	}
	.col-sm-12.leftmenu.mobileAssestActive .middle_scroll {
		bottom: 0px;
	}
	.middle_scroll .imagesfull span.tittle {
		height: 20px;
	}
	.navigation ul li:last-child a .summary {
		font-weight: normal;
		background: #fff;
	}

	.maninav {
		top: 0px;
		position: relative;
	}

	.maninav ul {
		margin-top: 0;
	}

	.col-sm-12.rightitems {
		padding: 0;
		overflow: hidden;
	}

	.col-sm-12.headingleft .mainhead {
		margin: 0;
	}

	.mobileAccessMenu .imagesfull {
		margin: 0;
	}

	.middle_scroll .imagesfull {
		width: 100%;
		padding-bottom: 8px;
		overflow: auto;
		white-space: nowrap;
	}

	.middle_scroll .col-sm-4 {
		display: inline-block;
		width: 64.55%;
	}

	.imagesborder img {
		height: 160px;
		width: 180px;
	}

	.col-sm-12.colors {
		padding: 0 20px;
		margin: 0;
	}

	.middle_scroll .col-sm-4 .imagesborder {
		height: auto;
		border: none;
		padding: 13px;
	}

	.middle_scroll .col-sm-4 .padding {
		border: 1px solid #ccc;
		padding: 0 0;
		border-radius: 10px;
	}

	.middle_scroll .col-sm-4 .padding.active {
		border-color: #006269;
	}

	.middle_scroll .col-sm-4 .padding .tittle {
		padding: 0 13px;
		min-height: auto;
		font-weight: 700;
		color: #868686;
		margin-bottom: 20px;
	}

	.middle_scroll .col-sm-12.colors .colorstext {
		font-weight: 600;
		color: #6f6f6f;
	}

	.middle_scroll {
		float: left;
		margin: 0;
		width: 100%;
		padding: 0 5px;
		/* position: absolute;
		bottom: 0; */
		left: 0;
	}
	.accordion__heading .accordion__button {
		font-size: 14px;
	}
	.filtertext p.mainhead {
		margin: 0;
		color: #2f2f2f;
		font-size: 14px;
		font-family: "Conv_ProximaNova-Regular";
		font-weight: 700;
	}

	.middle_scroll .col-sm-12.colors {
		margin-bottom: 10px;
		float: left;
		width: 100%;
	}

	.productMob {
		position: absolute;
		top: 60px;
		right: 0;
		width: 100%;
	}

	.wallimage {
		position: absolute;
		top: 60px;
		right: 0;
		width: 100%;
	}

	.doorimage {
		position: absolute;
		right: 0;
		width: 100%;
		top: 17px;
	}

	.rodimage {
		position: absolute;
		right: 0px;
		width: 100%;
		top: 60px;
		z-index: 99;
	}

	.fauctimage {
		position: absolute;
		top: 60px;
		right: 0;
		width: 100%;
		z-index: 99;
	}

	.soapimage {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		top: 60px;
	}

	.shelveimage {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		top: 60px;
	}

	.sideBarMain {
		position: absolute;
		width: 6%;
		top: 210px;
		right: 47%;
	}

	.sideBarMainHoriMid {
		position: absolute;
		width: 6%;
		top: 210px;
		right: 47%;
	}

	.sideBarMainHoriHigh {
		position: absolute;
		width: 6%;
		top: 200px;
		right: 47%;
	}

	.sideBarMainHoriLow {
		position: absolute;
		width: 6%;
		top: 220px;
		right: 47%;
	}

	.sideBarMainVertMid {
		position: absolute;
		width: 6%;
		top: 210px;
		right: 47%;
	}

	.sideBarMainVertHigh {
		position: absolute;
		width: 6%;
		top: 200px;
		right: 47%;
	}

	.sideBarMainVertLow {
		position: absolute;
		width: 6%;
		top: 220px;
		right: 47%;
	}

	.bordercolor {
		width: 100%;
		float: left;
		margin: auto;
		border: 1px solid #ccc;
		padding: 10px 15px;
		z-index: 999999;
	}

	.orientationsBars .col-sm-3 {
		width: 25%;
		float: left;
		padding: 0 10px;
		margin: 0;
	}

	.orientationsBars {
		width: 100%;
		float: left;
		margin: 20px 0 0 0;
	}

	.orientationsBars .col-sm-3:nth-child(1) {
		padding-left: 0;
	}

	.orientationsBars .col-sm-3 svg {
		margin: 0 !important;
	}

	.addbutton {
		text-align: center;
		z-index: 0;
		width: 50%;
		float: left;
		padding: 0;
	}

	.bottomPlacement .col-sm-12.colors span {
		float: left;
		margin: 0 5px 0 -0;
		padding: 10px 0;
		text-align: center;
		width: 100% !important;
	}

	.rightBar.col-sm-3 img {
		transform: rotate(116deg);
	}

	.horizontalBar.col-sm-3 img {
		transform: rotate(154deg);
	}

	.verticalBar.col-sm-3 img {
		transform: rotate(245deg);
		object-fit: contain;
		height: 100%;
	}

	#full-design-mobile {
		margin-top: 60px;
		position: initial;
	}
	
	div#Mobile .loaderImg img {
		top: 55%;
		transform: translateY(-55%);
	}
	.backicon img {
		max-width: 9px !important;
	}

	.backicon {
		color: #fff;
		display: inline-block;
		top: 50%;
		width: 30%;
		z-index: 99;
		position: absolute;
		transform: translateY(-50%);
	}

	.backicon em {
		font-style: normal;
		margin: 0 0 0 6px;
		font-size: 12px;
		font-weight: 500;
	}

	.headerbg {
		padding: 14px;
		position: fixed;
		top: 0;
		box-shadow: none;
	}

	.middle {
		width: 100%;
		float: left;
		margin: auto;
		padding: 0;
	}

	.middle li {
		list-style: none;
		display: inline-block;
		margin: 0 34px 0 6px;
	}

	.middle li a {
		display: inline-block;
		margin: auto;
	}

	.fixedbck {
		background: #40c1ac;
  		position: absolute;
  		right: 30px;
  		bottom: 36px;
  		width: 54px;
  		height: 54px;
  		border-radius: 100%;
  		display: flex;
  		justify-content: center;
  		align-items: center;
	}

	.middle_scroll .col-sm-12.colors .colorsdiv {
		width: 100%;
		float: left;
		margin: auto;
		position: relative;
		left: -8px;
	}

	.colorsdiv .boxescolor {
		width: 50px;
		float: left;
		margin: auto;
		text-align: center;
		height: 80px;
	}

	.colorsdiv {
		width: 100%;
		float: left;
		margin: auto;
	}

	.colortext {
		width: 100%;
		float: left;
		margin: 8px 0 0 0;
		color: #868686;
		font-style: normal;
		font-weight: 500;
	}

	.white {
		border-radius: 100%;
		display: inline-block;
		height: 40px;
		margin-right: 0;
		width: 40px;
		position: relative;
		top: 8px;
	}

	.navigation ul li.active a {
		border-right: 2px solid #ccc;
	}

	.navigation ul li.active i.round {
		background: #40c1ac;
	}

	.navigation {
		margin-top: 50px;
		z-index: 999999;
	}

	/*-----------pop up css ----------*/
	.modal .modal-dialog {
		max-width: 95%;
	}

	.modal .modal-dialog.book-consultant {
		max-width: 95%;
	}

	#exampleModal4 .modal-dialog {
		max-width: 95%;
	}

	#save .modal-dialog {
		max-width: 95%;
	}

	#missings .modal-dialog {
		max-width: 95%;
	}

	.startmid {
		font-size: 14px;
	}

	.buttoncenters {
		padding: 0 10px;
		text-align: center;
	}

	.backtext {
		margin-right: 20px;
	}

	.navigation ul li:last-child a .summary {
		top: 0;
	}

	.tittle {
		word-break: break-word;
		white-space: normal;
	}

	/*---------color for title like soap dishes etc---------*/

	#tablescrollOd table td b {
		font-family: "Conv_ProximaNova-Regular";
		font-weight: 700 !important;
		font-size: 12px;
	}

	#tablescrollOd table td:first-child {
		max-width: 110px;
		min-width: 110px;
		padding: 10px;
		text-align: center;
	}

	#tablescrollOd table td .images {
		float: none;
	}

	#tablescrollOd table td:nth-child(2),
	#tablescrollOd table td:nth-child(3) {
		font-size: 10px;
		color: #2f2f2f !important;
	}

	.mobileFaucetMenu .imagesfull {
		margin: 0 !important;
	}

	.mobileFaucetMenu .imagesfull .tittle {
		min-height: 40px !important;
	}

	.mobileDoorRodMenu .imagesfull {
		margin: 0;
	}

	.summaryTitle.col-sm-12 p.mainhead.backToDesign {
		margin: 0px 0 10px;
		float: left;
		width: 100%;
		font-weight: normal;
		line-height: 15px;
	}

	.congratssmalltex {
		width: 100%;  
		margin: 8px auto 20px;
		display: inline-block;
	}

	.summaryTitle.col-sm-12 .mainhead {
		float: left;
		width: 100%;
		margin: 5px 0 8px;
		font-size: 16px;
		font-weight: normal;
		line-height: 19px;
		color: #006269;
	}

	.mobileAccessMenu .col-sm-12.headingleft {
		width: 100%;
		float: left;
	}

	.mobileDoorRodMenu .col-sm-12.headingleft {
		width: 100%;
		float: left;
	}

	div#tablescrollOd table img#summaryBath {
		width: 100%;
		margin: 0px;
		max-width: 80%;
	}

	img#summaryWall {
		width: 100%;
		margin: 0px;
	}

	.loaderImg {
		position: absolute;
		left: 0;
		z-index: 99999;
		right: 0;
		top: 0;
		right: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.7);
		bottom: 0;
		height: 100%;
		margin: unset;
	}

	.loaderImg img {
		max-width: 7%;
		position: absolute;
		left: 0;
		margin: auto;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.alertMainDiv {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 999999;
	}

	.midditemss {
		position: absolute;
		background: #edf8ee;
		padding: 10px;
		left: 0;
		margin: auto;
		right: 0;
		top: 50%;
		display: table;
		transform: translateY(-50%);
		border-radius: 20px;
		max-width: 243px;
	}

	.msgText,
	.imagAlert {
		display: table-cell;
		vertical-align: middle;
		padding: 3px;
		/* margin: auto; */
	}

	/*---------css for page not found -----*/
	.big-text {
		font-size: 100px;
		margin-top: 110px;
	}

	span.imagesborder:before,
	span.imagesborder:hover:before {
		opacity: 0;
	}
}

@media only screen and (min-width: 767.5px) and (max-width: 1081px) {
	.bathtub.ceiling, .bathtub.Ceiling {
		height: unset !important;
	}
	
	.select-icons {
		height: 95vh !important;
	}
	.bottomfixed {
		position: fixed;
		bottom: 0;
		background: #006269;
		left: 0;
		right: 0;
		padding: 20px;
		text-align: left;
		z-index: 99;
	}
	.imagesfull.Walls-mobile img {
		object-fit: cover !important;
		width: 100%;
		height: 200px;
		border-radius: 10px;
	}
	.colorsdiv .boxescolor {
		height: auto;
	}
	.imagesfull.Walls-mobile .imagesborder {
		padding: 0;
	}
	/* .MobileNavLayout {
		position: fixed;
		z-index: 9;
		top: 0;
	} */
	.navigation {
		margin-top: 0 !important;
		z-index: 0;
	}
	.colorsdiv .boxescolor {
		width: 40px;
		margin-right: 16px;
	}
	

	.headerbg .container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.logo img {
		max-width: 100%;
	}
	.middle_scroll .col-sm-12.colors .colorstext {
		margin-top: 0px;
		display: inherit;
	}

	.imagesborder img {
		object-fit: contain;
	}
	span.headerNumer {
		height: 20px;
		line-height: 20px;
		width: 20px;
	}
	body .select-item .item-outer {
		height: 210px;
		width: 340px;
	}
	
	/* .MobileNavLayout {
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 9;
	} */
	/* .middle_scroll {
		height: 100% !important;
	} */
	.middle_scroll .col-sm-4 {
		width: 50%;
	}
	.col-sm-12.leftmenu {
		float: left;
		height: auto;
		margin: 5% auto;
		overflow: auto;
		padding-bottom: 30px;
		width: 100%;
	}
	.summaryTitle.col-sm-12 p.mainhead.backToDesign span {
		margin-right: 5px;
	}
	.middle_scroll .col-sm-4 {
		float: none;
	}
	.middle_scroll .imagesfull {
		overflow: auto;
		white-space: nowrap;
		width: 100%;
	}
	#Mobile {
		overflow-Y: hidden;
		display: block;
		overflow-x: hidden;
	}
	.modal .modal-dialog {
		height: auto !important;
		transform: translatey(-50%) !important;
	}
	.help-screen .help-info-header.book {
		margin-top: 155px !important;
	}
}

@media only screen and (min-width: 819px) and (max-width: 900px){
.col-sm-12.leftmenu {
    float: left;
    height: auto;
    margin: 3% auto 9%;
	padding-bottom:0;
}
.navigation ul li:last-child a .summary {
    top: 1px;
}
}

@media only screen and (min-width: 900px) and (max-width: 1080px){
	.col-sm-12.leftmenu {
		margin: 4% auto;}
	}

	@media only screen and (min-width: 1080px) and (max-width: 1281px){
		.col-sm-4.leftmenu {
			padding: 0 15px;
			width: 33%;
		}
		.design-container {
			margin-left: 33%;
		}
		.inner-elements img, .bg-img img {
			object-fit: cover;
		}
		.bottom.icons {
			right: 90px;
		}
		#my-node .loaderImg img {
			left: 33%;
			top: 55%;
			transform: translateY(-55%);
		}
	}


@media (max-width: 1199px) {
	.pre-config-main2.pre-config-main4 h2 {
		font-size: 18px;
		line-height: 22px;
	}

	.pre-config-main2 .design-exp {
		margin-bottom: 25px;
	}

	.pre-config-main2 {
		width: 80%;
		clear:unset;
	}

	.pre-config-main2.plumbing-width-lg h2 {
		font-size: 20px !important;
		margin: 15px 0 25px;
	}

	.back-to {
		padding-top: 80px;
	}

	.pre-config-main2.choose-showers {
		width: 90%;
	}

	.choose-showers .backgroundImage img {
		max-width: 70%;
	}
	.navigation {
		margin-top: 0px;
	}
    .design-container {
        top: 90px;
    }
}

@media (max-width: 1025px) {
	.left-image-layout {
		top: 95px;
	}
	.headerfixed header.headerbg .consult.frenchBtn {
		font-size: 10px;
	}
	/* .navigation ul li a {
		font-size: 10px;
	} */
}

@media (max-width: 992px) {
	.DesktopView .middimages .imagesfull .col-sm-4 {
		width: 50%;
	}
/* 
	.navigation ul li a {
		font-size: 11px;
	} */

	/* .doorimage {
		transform: scaleY(1.245);
		z-index: unset;
		bottom: unset;
		top: 26px;
	}

	.bathtub {
		top: 65px;
		transform: scale(1);
		bottom: unset;
	}

	.wallimage {
		transform: scale(1.01, 1.02);
		bottom: unset;
		top: 63px;
	} */

	.sideBarMain {
		position: absolute;
		width: 6%;
		top: unset;
		right: 47%;
		bottom: 38%;
	}

	.sideBarMainVertLow {
		bottom: 37%;
	}

	/* .rodimage {
		transform: scaleY(1.045);
		top: 45px;
	} */

	.imagesborder {
		padding: 5px;
	}

	.verticalBar.col-sm-3 img {
		object-fit: contain;
		height: 57%;
		top: 3px;
		position: relative;
	}

	.sideBarMainHoriHigh,
	.sideBarMainHoriLow,
	.sideBarMainHoriMid,
	.sideBarMainVertMid,
	.sideBarMainVertLow,
	.sideBarMainVertHigh {
		top: unset;
	}

	.navigation ul li a {
		padding: 9.5px 6px;
	}

	.saveicons ul.border li a {
		font-size: 12px;
	}
	.navigation {
		margin-top: 50px;
	}
}

@media (min-width: 768px) and (max-width:1199px){
	#savePopSave .modal-dialog {
		width: 340px;
	}
	.bottomzoomicons {
		padding: 8px 4.5px;
	}
	.iconsleft i.white {
		top: 2px;
		height: 30px;
		width: 30px;
	}
	.bottom.icons .iconsleft .imagesfull {
		margin-right: 10px;
	}
	.leftmenu.DesktopView .imagesfull .col-sm-4 {
		width: 50%;
	}
	.bordercolor span {
		margin-right: 5px;
	}
	.colors .bordercolor .white {
		height: 15px;
		width: 15px;
		top: 0px;
		margin-right: 5px;
	}
	.maninav ul li {
		display: inline-block;
	}
	.maninav ul .summaryMenu .summary-btn {
		height: auto;
		padding: 5px 10px;
		width: 70px;
		font-size: 11px;
		margin-left: 10px;
		line-height: 13px;
	}
	.rightsitems .savebtn {
		min-width: 80px;
	}
	.rightsitems .savebtn em {
		font-size: 12px;
	}
	.maninav li a img {
		max-width: 14px!important;
	}
	span.headerNumer {
		font-size: 11px;
		height: 14px;
		line-height: 14px;
		width: 14px;
	}
	.bordercolor span.selected {
		margin-left: 30px;
		margin-top: 5px;
	}
}

/* @media (max-width: 768.5px) {
	.doorimage {
		top: 32px;
		transform: scaleY(1.26);
	}

	.rodimage {
		transform: scaleY(1.055);
		top: 50px;
	}

	body .select-item .item-outer {
		width: 310px;
		height: 170px;
	}

	.design-satisfy h4 {
		font-size: 16px;
		line-height: 18px;
		margin-bottom: 5px;
	}

	.design-satisfy p {
		font-size: 14px;
	}

	.pre-config-info h2 {
		font-size: 24px;
	}

	#accessDiv .imagesborder {
		height: 70px;
		line-height: 3;
	}
	
} */





@media (max-width: 767.5px) {
	
	
	.maninav {
		top: 0px;
	}
	.bathtub {
		top: 60px;
		transform: scale(1.01);
		bottom: unset;
	}

	.sideBarMainHoriHigh,
	.sideBarMainHoriLow,
	.sideBarMainHoriMid,
	.sideBarMainVertMid,
	.sideBarMainVertLow,
	.sideBarMainVertHigh {
		bottom: unset;
	}

	.sideBarMain {
		top: 390px;
		bottom: unset;
	}

	.sideBarMainHoriLow {
		top: 410px;
	}

	.sideBarMainHoriMid {
		top: 380px;
	}

	.sideBarMainHoriHigh { 
		top: 340px;
	}

	.sideBarMainVertLow {
		top: 390px;
	}

	.sideBarMainVertMid {
		top: 360px;
	}

	.sideBarMainVertHigh {
		top: 320px;
	}

	.doorimage {
		top: -30px;
		transform: scaleY(1.225);
	}

	#desktop {
		display: none;
	}

	#Mobile {
		display: block;
		overflow-x: hidden;
		overflow-Y: hidden;
	}

	.logo img {
		max-width: 19%;
		height: 32px;
		object-fit: contain;
	}

	.logo {
		left: 0;
		position: relative;
		right: 0;
		text-align: center;
		top: 0;
	}


	body .load-existing {
		padding: 25px 0 35px;
	}

	.headerfixed header.headerbg .consult {
		right: 35px;
	}

	.navigation {
		margin-top: 0;
	}

	.maninav {
		overflow: auto;
		scrollbar-width: none; /* Firefox */
	}

	.middle_scroll .col-sm-4 .padding .tittle,
	.middle_scroll .col-sm-12.colors .colorstext,
	.colortext {
		font-size: 16px;
	}

	.colorsdiv .boxescolor {
		width: 40px;
		margin-right: 16px;
	}

	.navigation ul li a {
		font-size: 14px;
		font-family: "Conv_ProximaNova-Regular";
		font-weight: 600;
		color: #868686;
	}

	.alertMainDiv .cross {
		right: -10px;
		top: -13px;
		background: #f8eaea;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 50%;
		line-height: 30px;
		box-shadow: 0 0 13px 0 #00000042;
	}

	.intro-slider .slick-slide img {
		padding-top: 40px;
	}

	.bottomfixed {
		position: fixed;
		bottom: 0;
		background: #006269;
		left: 0;
		right: 0;
		padding: 20px;
		text-align: left;
		z-index: 99;
	}

	.navigation ul li.active span.headerNumer {
		border-color: #40c1ac;
	}

	.navigation ul li.active {
		background: transparent;
	}

	.MobileNavLayout {
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 9;
	}
	.middle_scroll {
		height: 100%;
	}
	
	.col-sm-12.headingleft, .col-sm-12.headingleft p {
		clear: both;
	}
	.bathtub.Shelves {
		z-index: 9;
	}
	
	.bathtub.Faucets , .bathtub.DoorsRods {
		z-index: 99;
	}
	.headerfixed header.headerbg .consult {
		font-size: 12px;
	}
	.navigation ul li.active a {
		color: #40c1ac;
	}
	.col-sm-12.leftmenu {
		float: left;
		width: 100%;
		margin: auto;
		height: 100%;
		overflow: auto;
		padding-bottom: 60px;
		margin-top: 98%;
	}
	.mobileContentData {
		margin-top: 10%;
	}
}

@media (max-width: 671px) {
	body .select-item .item-outer {
		width: 250px;
		height: 170px;
	}

	.intro-slider .slick-slide img {
		padding-top: 30px;
	}
	
}

@media only screen and (min-width:551px) and (max-width:767px){
	/* .col-sm-12.leftmenu {
		bottom: -70px;
	} */
}


@media (max-width: 576px) {
	div#Mobile .headerfixed:nth-child(2) {
		display: none;
	}
	
	
	.sideBarMain,
	.sideBarMainHoriMid,
	.sideBarMainVertLow {
		top: 300px;
	}

	.sideBarMainHoriLow {
		top: 320px;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertMid {
		top: 270px;
	}

	.sideBarMainVertHigh {
		top: 260px;
	}

	.doorimage {
		top: -13px;
	}

	.rodimage {
		top: 40px;
	}

	body .select-item .item-outer {
		width: 180px;
		height: 170px;
	}


	.summary {
		font-size: 14px;
		padding: 10px 15px;
	}

	.summary .fas.fa-chevron-right {
		right: 9px;
		top: 13px;
	}

	#missings .modal-header {
		padding: 30px 20px 10px;
	}

	#missings .modal-fotter .emailtexts {
		font-size: 13px;
	}

	#missings .modal-fotter {
		padding: 23px 20px 30px 20px;
	}

	#missings .backtext {
		margin-right: 10px;
		font-size: 11px;
	}

	#savePopSave .modal-content {
		border: none;
	}

	.copywith .form-control {
		padding: 11.5px 90px 11.5px 40px;
		border-radius: 3px;
	}

	.copywith button.copy {
		right: 15px;
        top: 50%;
        border-radius: 3px;
        font-size: 14px;
        padding: 0;
        transform: translateY(-50%);
	}

	#share .modal-header {
		padding: 21px 15px 0;
	}

	#share .modal-body {
		padding: 20px 5px 20px;
	}

	#headerLoader {
		width: 20px;
		height: 20px;
		position: relative;
		top: 10px;
	}
}

@media (max-width: 481px) {
	/* .col-sm-12.leftmenu {
		height: 55%;
	} */
	.sideBarMain {
		top: 260px;
	}

	.sideBarMainHoriLow {
		top: 280px;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertMid {
		top: 240px;
	}

	.sideBarMainHoriMid,
	.sideBarMainVertLow {
		top: 260px;
	}

	.sideBarMainVertHigh {
		top: 220px;
	}

	.doorimage {
		top: -7px;
		transform: scaleY(1.26);
	}

	.mobileRodImg li .imagesborder img {
		max-height: 111px;
	}

	body .select-item .item-outer {
		width: 180px;
		height: 170px;
	}

	.headerfixed header.headerbg .consult {
		right: 15px;
		height: 32px;
	}

	.col-sm-5.addbutton .clear,
	.middle_scroll .col-sm-4 .padding .tittle,
	.middle_scroll .col-sm-12.colors .colorstext,
	.summaryTitle.col-sm-12 p.mainhead.backToDesign {
		font-size: 12px;
	}

	.colortext {
		font-size: 10px;
		line-height: 10px;
	}

	.congrats {
		font-size: 20px;
		line-height: 19px;
	}

	.congratssmalltex {
		font-size: 12px;
		line-height: 15px;
		max-width: 80%;
		color: #000;
	}

	body .intro-slider .slick-slide img {
		padding-top: 10px;
	}

	.headerfixed header.headerbg .consult {
		width: 98px;
	}
	.country-code .ReactFlagsSelect-module_selectBtn__19wW7 {
		width: 22%;
	}
	input.form-control.phone {
		width: 71%;
	}
}
@media only screen and (min-width: 500px) and (max-width:767px){
/* .col-sm-12.leftmenu {
    bottom: -40px;
} */
.col-sm-12.leftmenu {
    margin-top: 91%;
}
}


@media only screen and (min-width: 421px) and (max-width:500px) {
	.select-icons.intro-page .intro-slider {
		margin-top: 100px;
	}
	/* .col-sm-12.leftmenu {
		float: left;
		margin: 0;
		overflow: auto;
		width: 100%;
		position: fixed;
		bottom: 70px;
	} */
	/* .col-sm-12.leftmenu {
		bottom: -60px;
	} */
	.select-icons.intro-page , .select-icons{
		height: 90vh !important;
	}
	.select-icons{height: 89vh !important;}
}
	 
@media (max-width: 425px) {
	.sideBarMain {
		top: 240px;
	}

	.sideBarMainHoriLow {
		top: 250px;
	}

	.sideBarMainHoriMid,
	.sideBarMainVertLow {
		top: 235px;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertMid {
		top: 220px;
	}

	.sideBarMainVertHigh {
		top: 200px;
	}

	.doorimage {
		top: 0;
		transform: scaleY(1.26);
	}

	.rodimage {
		top: 45px;
	}
}


@media only screen and (min-width: 380px) and (max-width:420px) {

	.saved-project .selector-title {
		padding: 28px 25px 0 !important;
		margin: 0;
	}
	/* .col-sm-12.leftmenu {
		bottom: -30px;
	} */

	.select-icons h4 {
		font-size: 22px !important;
		margin: 0 0 15px !important;
	}
	.select-icons.intro-page {
		height: 84vh !important;
	}
	.select-icons{height: 81vh !important;}

}
@media (max-width: 420px) {
	.sideBarMain {
		top: 230px;
	}

	.sideBarMainHoriMid,
	.sideBarMainVertLow {
		top: 230px;
	}

	.sideBarMainHoriLow {
		top: 245px;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertMid {
		top: 210px;
	}

	.sideBarMainVertHigh {
		top: 200px;
	}

	.doorimage {
		top: 0;
		transform: scaleY(1.265);
	}

	.rodimage {
		transform: scaleY(1.05);
		top: 46px;
	}

	.orientationsBars .col-sm-3 {
		width: 50%;
		padding: 0 5px;
	}

	.orientationsBars .col-sm-3:nth-child(1) {
		padding-left: 5px;
	}

	body .select-item .item-outer {
		width: 150px;
		height: 150px;
	}
}

@media (max-width: 380px) {
	
	.sideBarMain {
		top: 215px;
	}

	.sideBarMainHoriMid,
	.sideBarMainVertLow {
		top: 210px;
	}

	.sideBarMainHoriHigh,
	.sideBarMainVertMid {
		top: 190px;
	}

	.sideBarMainVertHigh {
		top: 180px;
	}

	.sideBarMainHoriLow {
		top: 225px;
	}

	.bottomPlacement .bordercolor {
		padding: 0 0 15px;
	}

	.doorimage {
		top: 7px;
		transform: scaleY(1.265);
	}

	.follow-steps-mobile p {
		max-width: 60%;
	}
 
}

@media (max-width: 360px) {
	.summary {
		font-size: 13px;
		padding: 10px;
	}

	.summary .fas.fa-chevron-right {
		right: 3px;
		top: 13px;
	}

	.doorimage {
		top: 14px;
	}

	.summary {
		font-size: 13px;
	}

	.rodimage {
		transform: scaleY(1.03);
		top: 56px;
	}

	body .select-item .item-outer {
		width: 130px;
		height: 130px;
	}
	.country-code .ReactFlagsSelect-module_selectBtn__19wW7 {
		width: 25%;
	}
	input.form-control.phone {
		width: 67%;
	}
	.select-icons.intro-page{
		height: 90vh !important;
	}
	.select-icons {
		height: 87vh !important;
	}
	#tablescrollOd .filtertext.middletextcenter {
		padding: 5% 0 0;
	}
	body .select-icons h4 {
		font-size: 22px;
		line-height: 25px;
	}
}

@media (max-width: 320px) {
	.intro-slider {
		padding: 42px 0  90px!important;
	}
	.wallimage {
		transform: scale(1.01, 1.04);
		bottom: unset;
		top: 63px;
	}

	.sideBarMain {
		top: 195px;
	}

	.middle_scroll .col-sm-12.colors {
		padding: 0;
	}

	body .select-icons h4 {
		font-size: 20px;
		line-height: 22px;
		margin: 0 0 10px;
	}

	body .follow-steps-mobile p {
		font-size: 15px;
	}

	body .select-item {
		margin-top: 20px;
	}

	body .select-item .item-outer>img {
		padding-top: 10px;
	}

	body .select-plumbing .item-outer>img {
		max-width: 40%;
	}

	body .item-title p {
		font-size: 12px;
	}

	body .get-started button.started {
		width: 220px;
		height: 55px;
		font-size: 18px;
	}

	body .help-screen.select-icons {
		padding: 15px;
	}
}

@media (max-width: 300px) {
	body .select-item .item-outer {
		width: 110px;
		height: 110px;
	}
}

@media only screen and (min-width: 761px) and (max-width: 999px) {

	/*---------css for page not found -----*/
	.big-text {
		font-size: 100px;
		margin-top: 110px;
	}
}

@media (min-width: 1900px) {
	.pre-config-info {
		width: 750px;
		padding: 54px 79px;
	}

	.pre-config-info h2 {
		font-size: 44px;
		font-family: "PT Serif";
		line-height: 50px;
		margin-bottom: 18px;
	}

	.pre-config-main4 h2 {
		font-size: 24px;
	}

	.pre-config-info a .follow-steps>div {
		position: relative;
		left: -40px;
	}

	.pre-config-main2 {
		width: 68%;
		clear: both;
	}
	.pre-config-info p {
		font-size: 22px;
		line-height: 27px;
		margin-bottom: 18px !important;
	}

	.pre-config-info h4 {
		font-size: 22px;
	}

	.hover-icon {
		width: 60px;
		height: 60px;
		line-height: 60px;
	}

	.pre-config-main2 .left-right-img {
		display: flex;
		justify-content: center;
	}

	.pre-config-main2 h2 {
		font-size: 44px;
		line-height: 50px;
	}

	.plumbing-width-lg .left-right-img {
		width: 63%;
		margin: auto;
	}

	.plumbing-width-lg h2 {
		margin-top: 37px;
		box-sizing: border-box;
		margin-bottom: 0;
		font-family: PTSerif-Regular;
	}

	.pre-config-main2 .left-right-img p {
		font-size: 20px;
	}

	.hover-icon i {
		font-size: 22px;
	}

	.pre-config-main4 h2 {
		font-size: 42px;
		margin-bottom: 45px;
		font-family: PTSerif-Regular;
		line-height: 50px;
	}
	.choose-showers h2 {
		font-size: 42px;
		line-height: 50px;
		margin-top: 37px;
		margin-bottom: 45px;
	}

	.back-to {
		padding-top: 105px;
		margin-bottom: 0;
	}

	.pre-config-main2 .design-exp {
		margin-top: 12px;
		margin-bottom: 46px;
		font-size: 24px;
	}

	.satisfaction-check {
		padding: 20px;
	}

	.design-satisfy h4 {
		font-size: 24px;
	}

	.design-satisfy p {
		line-height: 30px;
		font-size: 22px;
	}

	.back-to.back-to-summary a {
		font-size: 16px;
	}

	.load-project-inner .load-email-field {
		width: 406px;
	}

	.navigation ul li a,
	.filtertext p.mainhead {
		font-size: 18px;
		line-height: 20px;
	}

	.col-sm-4 .padding span.tittle {
		font-size: 15px;
	}

	.navigation ul li a {
		padding: 14px 17px;
		height: 50px;
		display: flex;
		align-items: center;
	}

	.navigation ul ul.dropdown-menu {
		height: unset;
	}

	.navigation {
		height: 50px;
	}

	span.headerNumer {
		position: relative;
		top: -1px;
	}

	.navigation ul {
		height: 50px;
	}

	.summary-btn {
		font-size: 18px;
	}

	.imagesborder img {
		height: 160px;
		width: 180px;
	}

	.bordercolor span {
		font-size: 15px;
	}

	#missings button.summary {
		width: 278px;
		height: 70px;
		font-size: 25px;
		font-weight: bold;
	}

	#missings .backtext {
		font-size: 16px;
		margin-right: 30px;
		line-height: 19px;
	}
	.headerbg .consult {
		font-size: 14px;
		font-weight: 700;
		width: 238px;
		height: 44px;
		line-height:15px;
	}
	.logo img {
		max-width: 18%;
	}
	.headerbg {
		height: 75px;
		display: flex;
		align-items: center;
	}
	.headerbgFixed {
		height: 75px;
		display: flex;
		align-items: center;
	}
	.headerbgFixed .consult {
		font-size: 14px;
		font-weight: 700;
		width: 238px;
		height: 44px;
		line-height:15px;
	}
	.load-project-inner h2 {
		font-size: 42px;
		line-height: 50px;
		margin-top: 37px;
		margin-bottom: 17px;
	}
	.load-project-inner .instructions {
		font-size: 24px;
	}
	.col-sm-5.addbutton .clear {
		font-size: 16px;}

}


