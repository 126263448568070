body{margin:0; padding:0; box-sizing:border-box; overflow-x:hidden;}
.container, img {
 max-width: 100%;
}
.container{padding:0; width:100%;}

.design-container {
    position: fixed;
    overflow: hidden;
    right: 0;
    top: 118px;
    bottom: 0;
    left: 0;
    margin-left: 40%;
}

.product{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    width: 100%;
    height: 100%;
}
.left-image-layout {
    position: relative;
    width: auto;
    height: 100%;
    max-width: none;
    max-height: none;
    touch-action: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    user-select: none;
}
.inner-elements img, .bg-img img {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    object-fit: cover;
}
.left-image-layout .bg-img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.bathtub.tubfronts, .bathtub.showerbase, .bathtub.Tubfronts, .bathtub.Showerbase {
    z-index: 2;
}
.bathtub.walls, .bathtub.Walls {
    z-index: 1;
}
.bathtub.faucets, .bathtub.Faucets {
    z-index: 4;
}
.bathtub.grabbars, .bathtub.Grabbars {
    z-index: 5;
}
.bathtub.shelves, .bathtub.soapdishes, .bathtub.seats, .bathtub.Shelves, .bathtub.Soapdishes, .bathtub.Seats {
    z-index: 3;
}
.bathtub.rods, .bathtub.doors, .bathtub.DoorsRods {
    z-index: 6;
}
.bathtub.wainscoting, .bathtub.Wainscoting {
    z-index: 2;
    width: 100%;
}
.bathtub.ceiling, .bathtub.Ceiling {
    z-index: 2;
    height: 100%;
}

.draggable {
    cursor: grab;
}
.dragging {
    cursor: grabbing;
}

.showerbase {
    z-index: 9;
}



