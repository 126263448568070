/** Generated by FG **/
@font-face {
    font-family: 'Conv_Proxima Nova Thin';
    src: url('./fonts/Proxima Nova Thin.eot');
    src: local(''), url('./fonts/Proxima Nova Thin.woff') format('woff'), url('./fonts/Proxima Nova Thin.ttf') format('truetype'), url('./fonts/Proxima Nova Thin.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

/** Generated by FG **/
@font-face {
    font-family: 'Conv_ProximaNova-Regular';
    src: url('./fonts/ProximaNova-Regular.eot');
    src: local(''), url('./fonts/ProximaNova-Regular.woff') format('woff'), url('./fonts/ProximaNova-Regular.ttf') format('truetype'), url('./fonts/ProximaNova-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

/** Generated by FG **/
@font-face {
    font-family: 'Conv_Proxima Nova Extrabold';
    src: url('./fonts/Proxima Nova Extrabold.eot');
    src: local(''), url('./fonts/Proxima Nova Extrabold.woff') format('woff'), url('./fonts/Proxima Nova Extrabold.ttf') format('truetype'), url('./fonts/Proxima Nova Extrabold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

/** Generated by FG **/
@font-face {
    font-family: 'Conv_Proxima Nova Black';
    src: url('./fonts/Proxima Nova Black.eot');
    src: local(''), url('./fonts/Proxima Nova Black.woff') format('woff'), url('./fonts/Proxima Nova Black.ttf') format('truetype'), url('./fonts/Proxima Nova Black.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

/** Generated by FG **/
@font-face {
    font-family: 'Conv_Proxima Nova Bold';
    src: url('./fonts/Proxima Nova Bold.eot');
    src: local(''), url('./fonts/Proxima Nova Bold.woff') format('woff'), url('./fonts/Proxima Nova Bold.ttf') format('truetype'), url('./fonts/Proxima Nova Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

/** Generated by FG **/
@font-face {
    font-family: 'Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold';
    src: url('./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.eot');
    src: local(''), url('./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.woff') format('woff'), url('./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.ttf') format('truetype'), url('./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}



/** Generated by FG **/
/** Generated by FG **/
@font-face {
    font-family: 'Conv_Proxima Nova Semibold';
    src: url('./fonts/Proxima Nova Semibold.eot');
    src: local(''), url('./fonts/Proxima Nova Semibold.woff') format('woff'), url('./fonts/Proxima Nova Semibold.ttf') format('truetype'), url('./fonts/Proxima Nova Semibold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

#designDetails {
    font-family: 'Conv_Proxima Nova Semibold';
    font-size: 20px !important;
    width: 100% !important;
    float: left !important;
    margin: 30px 0 !important;
    color: #006269 !important;
}

#creationDate {
    font-family: 'Conv_Proxima Nova Semibold';
    font-size: 20px !important;
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    color: #2f2f2f !important;
}

#createdBy {
    font-family: 'Conv_Proxima Nova Semibold';
    font-size: 20px !important;
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    color: #2f2f2f !important;
}

#firstMainDiv {
    width: 100% !important;
    float: left !important;
    margin: auto !important;
}

#secondMainChildDiv {
    width: 45% !important;
    float: left !important;
    margin: auto !important;
}

.BathTubTitle {
    background: #e5eff0 !important;
    float: left !important;
    width: 100% !important;
    padding: 10px !important;
    margin: 0 0 10px !important;
    box-sizing: border-box !important;
}

.tubPtag {
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    color: #2f2f2f !important;
    font-size: 15px !important;
    font-family: 'Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold';
}

#bathTable {
    width: 100% !important;
    float: left !important;
    margin: 0 0 20px !important;
}
.bathTable {
    width: 100% !important;
    float: left !important;
    margin: 0 0 20px !important;
}
.tdbathfirst {
    width: 40% !important;
}
.spanFirst {
    width: 100% !important;
    float: left !important;
}
.bathImagetd{
    max-width: 60% !important;  
}
.tdbathsecond {
    font-family: 'Conv_Proxima Nova Semibold';
    width: 60% !important;
    font-size: 19px !important;
    line-height: 27px !important;
}
#tdbathfirst {
    width: 40% !important;
}

#spanFirst {
    width: 100% !important;
    float: left !important;
}

#bathImagetd {
    max-width: 60% !important;
}

#tdbathsecond {
    font-family: 'Conv_Proxima Nova Semibold';
    width: 60% !important;
    font-size: 19px !important;
    line-height: 27px !important;
}

#wallDiv {
    background: #e5eff0 !important;
    float: left !important;
    width: 100% !important;
    padding: 10px !important;
    margin: 0 0 10px !important;
    box-sizing: border-box !important;

}

#wallTable {
    width: 100% !important;
    float: left !important;
    margin: 0 0 20px !important;
}

#walltdfirst {
    width: 40% !important;
}

#wallspantd {
    width: 100% !important;
    float: left !important;
}

#wallimage {
    max-width: 60% !important;
}

#wallSecondtd {
    font-family: 'Conv_Proxima Nova Semibold';
    width: 60% !important;
    font-size: 19px !important;
    line-height: 27px !important;
}

#wallp {
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    color: #2f2f2f !important;
    font-size: 15px !important;
    font-family: 'Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold';
}

#rightChildDiv {
    width: 45% !important;
    float: right !important;
    margin: auto !important;
}

#rightAccessDiv {
    background: #e5eff0 !important;
    float: left !important;
    width: 100% !important;
    padding: 10px !important;
    margin: 0 0 10px !important;
    box-sizing: border-box !important;
}

#rightAccessP {
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    color: #2f2f2f !important;
    font-size: 15px !important;
    font-family: 'Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold';
}

#rightTable {
    width: 100% !important;
    float: left !important;
    margin: 0 0 20px !important;
}

#rightTableTdF {
    width: 40% !important;
}

#rightSpan {
    width: 100% !important;
    float: left !important;
}

#shelveImg {
    max-width: 60% !important;
}

#shelveSecTd {
    font-family: 'Conv_Proxima Nova Semibold';
    width: 60% !important;
    font-size: 19px !important;
    line-height: 27px !important;
}

#footerDiv {
    width: 100% !important;
    float: left !important;
    margin: 0px 0 20px !important;
}

#readyFooter {
    font-family: 'Conv_Proxima Nova Semibold';
    font-size: 20px !important;
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    color: #2f2f2f !important;
    text-align: center !important;
}

#footerSecond {
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
}

#FooterP {
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    padding: 20px !important;
    box-sizing: border-box !important;
    margin-bottom: 20px !important;
    border: 2px solid #ccc !important;
    text-align: center !important;
    font-size: 23px !important;
    font-family: 'Conv_Proxima Nova Semibold';
    line-height: 43px !important;
}

#emOne {
    font-style: normal !important;
    color: #006269 !important;
    font-family: 'Conv_Proxima Nova Bold';
}

#emSecond {
    font-style: normal !important;
    color: #006269 !important;
    font-family: 'Conv_Proxima Nova Bold';
}

#emThired {
    font-style: normal !important;
    color: #006269 !important;
    font-family: 'Conv_Proxima Nova Bold';
}

#defaultImage {
    width: 40% !important;
    float: left !important;
    margin-bottom: auto !important;
}

#defaultSpanImg {
    width: 100% !important;
    float: left !important;
    margin-top: 40px !important;
}

#defaultImgsize {
    width: 100% !important;

}

#bathtubPdf {
    width: 39% !important;
    /* z-index: 99999999 !important; */
    bottom: 72% !important;
    left: 59% !important;
    position: absolute !important;
}

#wallPdf {
    width: 38% !important;
    bottom: 72% !important;
    position: absolute !important;
}

#fPdf {
    width: 38% !important;
    bottom: 72% !important;
    position: absolute !important;
    left: 59% !important;
    z-index: 99999999 !important;
}

#shelvePdf {
    width: 38% !important;
    bottom: 72% !important;
    position: absolute !important;
    left: 59% !important;
}

#soapPdf {
    position: absolute !important;
    left: 59% !important;
    bottom: 72% !important;
}

#roadPdf {
    position: absolute !important;
    bottom: 72% !important;
}

#doorPdf {
    position: absolute !important;
    bottom: 72% !important;
    width: 38% !important;
    z-index: 9999999999;
}

#rightBarPdf {
    bottom: 82% !important;
    width: 5% !important;
    left: 77% !important;
    transform: rotate(116deg) !important;
    position: absolute !important;
}

#leftBarPdf {
    bottom: 82% !important;
    width: 5% !important;
    left: 77% !important;

    position: absolute !important;
}

#horiBarPdfMid {
    bottom: 82% !important;
    width: 5% !important;
    left: 77% !important;
    transform: rotate(154deg) !important;
    position: absolute !important;
}

#horiBarPdfMidHigh {
    bottom: 83% !important;
    width: 5% !important;
    left: 77% !important;
    transform: rotate(154deg) !important;
    position: absolute !important;
}

#horiBarPdfMidLOW {
    bottom: 81% !important;
    width: 5% !important;
    left: 77% !important;
    transform: rotate(154deg) !important;
    position: absolute !important;
}

#vertBarPdfMid {
    bottom: 83% !important;
    width: 5% !important;
    left: 77% !important;
    transform: rotate(245deg) !important;
    position: absolute !important;
}

#vertBarPdfMidHigh {
    bottom: 84% !important;
    width: 5% !important;
    left: 77% !important;
    transform: rotate(245deg) !important;
    position: absolute !important;
}

#vertBarPdfMidLow {
    bottom: 82% !important;
    width: 5% !important;
    left: 77% !important;
    transform: rotate(245deg) !important;
    position: absolute !important;
}

#titleWt {
    font-family: 'Conv_ProximaNova-Regular';
}
.titleWt {
    font-family: 'Conv_ProximaNova-Regular';
}

#titleColor {
    font-family: 'Conv_ProximaNova-Regular';
}
.titleColor {
    font-family: 'Conv_ProximaNova-Regular';
}

