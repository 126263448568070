/** Generated by FG **/
@font-face {
	font-family: "Conv_Proxima Nova Thin";
	src: url("./fonts/Proxima Nova Thin.eot");
	src: local(""), url("./fonts/Proxima Nova Thin.woff") format("woff"),
		url("./fonts/Proxima Nova Thin.ttf") format("truetype"),
		url("./fonts/Proxima Nova Thin.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: "Conv_ProximaNova-Regular";
	src: url("./fonts/ProximaNova-Regular.eot");
	src: local(""), url("./fonts/ProximaNova-Regular.woff") format("woff"),
		url("./fonts/ProximaNova-Regular.ttf") format("truetype"),
		url("./fonts/ProximaNova-Regular.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: "Conv_Proxima Nova Extrabold";
	src: url("./fonts/Proxima Nova Extrabold.eot");
	src: local(""), url("./fonts/Proxima Nova Extrabold.woff") format("woff"),
		url("./fonts/Proxima Nova Extrabold.ttf") format("truetype"),
		url("./fonts/Proxima Nova Extrabold.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: "Conv_Proxima Nova Black";
	src: url("./fonts/Proxima Nova Black.eot");
	src: local(""), url("./fonts/Proxima Nova Black.woff") format("woff"),
		url("./fonts/Proxima Nova Black.ttf") format("truetype"),
		url("./fonts/Proxima Nova Black.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: "Conv_Proxima Nova Bold";
	src: url("./fonts/Proxima Nova Bold.eot");
	src: local(""), url("./fonts/Proxima Nova Bold.woff") format("woff"),
		url("./fonts/Proxima Nova Bold.ttf") format("truetype"),
		url("./fonts/Proxima Nova Bold.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: "Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold";
	src: url("./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.eot");
	src: local(""),
		url("./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.woff") format("woff"),
		url("./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.ttf") format("truetype"),
		url("./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
/** Generated by FG **/
@font-face {
	font-family: "Conv_Proxima Nova Semibold";
	src: url("./fonts/Proxima Nova Semibold.eot");
	src: local(""), url("./fonts/Proxima Nova Semibold.woff") format("woff"),
		url("./fonts/Proxima Nova Semibold.ttf") format("truetype"),
		url("./fonts/Proxima Nova Semibold.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: "Conv_ProximaNova-Regular";
	color: #2f2f2f;
	font-size: 15px;
	height: 100%;
	width: 100%;
	float: left;
	margin: 0;
	background: #fff;
}

img {
	max-width: 100%;
}

.container {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

/*Top header CSS*/
.headerbg {
	background: #006269;
	box-shadow: 0 7px 7px rgb(0 0 0 / 20%);
	float: left;
	margin: auto;
	padding: 13px 0;
	position: relative;
	width: 100%;
	z-index: 9999;
}

.headerbgFixed {
	background: #006269;
	float: left;
	margin: auto;
	padding: 13px 0;
	position: fixed;
	width: 100%;
	z-index: 9999;
}

.backicon {
	padding: 0;
	position: relative;
	left: 11px;
	top: 5px;
	width: 2%;
}

.logo {
	padding: 0;
}

.logo {
	left: 5px;
}

.headerfixed {
	left: 0;
	position: fixed;
	right: 0;
	z-index: 999999;
}

.backicon,
.logo {
	padding: 0;
	position: relative;
}

.logo img {
	max-width: 22%;
}

.consult {
	background: #f5875f;
	border: 1px solid #f5875f;
	border-radius: 5px;
	color: #fff;
	float: right;
	font-family: "Conv_Proxima Nova Semibold";
	font-size: 10px;
	padding: 7px 11px;
	position: relative;
	right: 15px;
	text-transform: uppercase;
	top: 0;
}

.headerfixed header.headerbg .consult {
    font-size: 14px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 15px;
}

.backicon img {
	max-width: 20%;
}

/*Navigation css*/
.navigation {
	background: #fff;
	box-shadow: 0 7px 7px rgb(0 0 0 / 20%);
	float: left;
	margin: auto;
	padding: 0;
	width: 100%;
	position: relative;
	z-index: 9999;
}

.navigation ul {
	margin: 0;
	padding: 0 0 0 0;
	width: 80%;
	float: left;
}

.navigation ul li {
	list-style: none;
	margin: auto;
	display: inline-block;
	float: left;
	padding: 0 0;
}

.navigation ul li a {
	border-right: 2px solid #ccc;
	color: #868686;
	float: left;
	font-size: 15px;
	padding: 12px 16px 12px 16px;
	text-decoration: none;
	width: 100%;
	font-family: "Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold";
}

.navigation ul li.active {
	background: #40c1ac;
}

.open ul.dropdown-menu {
	min-width: 100%;
	padding: 0;
	top: 40px;
	width: 250px;
}

.navigation .open ul.dropdown-menu li a {
	background: none;
	border: none !important;
	color: #000;
	font-size: 13px;
	padding: 10px 14px;
}

.open ul.dropdown-menu li.dropdown-submenu:hover ul.dropdown-menu {
	display: block;
	left: 267px;
	top: -2px;
	width: 450px;
}

.open ul.dropdown-menu li.dropdown-submenu:hover ul.dropdown-menu:after {
	border-bottom: 15px solid transparent;
	border-right: 15px solid #fff;
	border-top: 15px solid transparent;
	content: "";
	height: 0;
	left: -20px;
	position: absolute;
	top: 11px;
	width: 0;
}

.smallhead,
.style {
	float: left;
	width: 100%;
}

.style {
	border-bottom: 1px solid #ccc;
	font-family: Conv_FontsFree-Net-Proxima-Nova-Sbold;
	margin: auto;
	padding: 10px 15px;
}

.smallhead {
	font-size: 14px;
}

.fullimages {
	float: left;
	margin: auto;
	width: 100%;
}

.fullimages .col-sm-3 {
	padding: 0;
}

.middimages,
.padding {
	float: left;
	margin: auto;
	width: 100%;
}

.padding {
	padding: 0 10px;
}

.bordercolor,
.imagesborder {
	cursor: pointer;
}

.imagesborder {
	border: 1px solid #f8f6f3;
	float: left;
	height: 160px;
	line-height: 4;
	margin: auto auto 10px;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-align: center;
	transition: all 0.2s ease-in-out;
	width: 100%;
}

#shelves .imagesborder,
#soapdishes .imagesborder,
#grabbars .imagesborder,
#doors .imagesborder,
#rods .imagesborder {
	height: 110px;
}

.tittle {
	color: #2e2e2e;
	float: left;
	font-family: Conv_ProximaNova-Regular;
	font-size: 13px;
	margin: auto auto 5px;
	max-height: 60px;
	min-height: 44px;
	position: relative;
	width: 100%;
	word-break: break-word;
}

#doormid .imagesborder img {
	width: 100%;
	height: 100%;
}

.open ul.dropdown-menu li {
	border-bottom: 1px solid #ccc;
	float: left;
	padding: 0;
	position: relative;
	width: 100%;
}

.maninav .pull-right a {
	padding: 0 17px;
	border: none;
}

.maninav .pull-right ul {
	width: 100%;
}

.round {
	width: 20px;
	height: 20px;
	border: 1px solid #868686;
	display: inline-block;
	text-align: center;
	line-height: 20px;
	border-radius: 100%;
	font-style: normal;
	margin-right: 5px;
	font-size: 12px;
	font-family: "Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold";
}

.navigation ul li.active i.round {
	color: #fff;
	border-color: #fff;
}

.navigation ul li.active a {
	color: #fff;
	border: none;
}

.maninav .pull-right a i {
	margin-right: 10px;
}

.maninav .pull-right a {
	color: #006269;
	text-decoration: underline;
}

.maninav .pull-right ul li {
	padding: 9px 0px;
}

.navigation ul li:last-child {
	border: none;
}

.navigation ul li:last-child a {
	border: none;
}

.navigation ul li:last-child a .summary {
	border: 2px solid #006269;
	color: #006269;
	font-size: 12px;
	padding: 8px 20px;
	border-radius: 3px;
	font-family: "Conv_Proxima Nova Bold";
	position: relative;
	top: 2px;
}

li.others a {
	border: none !important;
}

.navigation ul li.nav-item.dropdown a {
	border: none;
	position: relative;
	top: 0;
	color: #000;
	font-family: "Conv_ProximaNova-Regular";
}

.active .imagesborder {
	border-color: #006269;
}

/*Leftwidth or Right Width*/
.col-sm-4.leftmenu {
	background: #fff;
	height: 88vh;
	overflow: auto;
	padding: 0 30px;
	width: 40%;
	z-index: 9997;

}

/* Hide scrollbar by default */
.maninav::-webkit-scrollbar {
    width: 0px;
	height: 0px;
}

.col-sm-8.rightitems {
	/* width: 60%;
        padding-right: 0;
        overflow: auto; */
	overflow: hidden;
	padding-right: 0;
	width: 60%;
	background: #fff;
	height: 100vh;
}

.row.marginpadding {
	margin-right: -9px;
	margin-left: -9px;
}

/*BathTub*/
.filtertext {
	background: #e5eff0;
	float: left;
	width: 100%;
	padding: 13px 0;
	margin: 20px 0;
}

.middimages .imagesfull .col-sm-4 {
	padding: 0;
}

.filtertext.active {
	background: #40c1ac;
}

.filtertext p.mainhead {
	margin: 0;
	color: #2f2f2f;
	font-size: 15px;
	font-family: "Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold";
}

.addbutton {
	text-align: right;
	z-index: 9;
}

.filtertext.active {
	background: #40c1ac;
}

.clear {
	color: #fff !important;
	font-size: 14px;
	text-decoration: underline;
}

.filtertext.active p.mainhead {
	color: #fff;
}

/*Color Css*/
.bordercolor {
	width: 100%;
	float: left;
	margin: auto;
	border: 1px solid #ccc;
	padding: 10px 15px;
	z-index: 999999;
	display: flex;
    align-items: center;
	flex-wrap: wrap;
}

.white.active {
	border: 2px solid #00696a;
}

.white {
	border: 1px solid #ccc;
	border-radius: 100%;
	display: inline-block;
	height: 30px;
	margin-right: 10px;
	width: 30px;
	position: relative;
	top: 0px;
}

.white.two {
	background: rgb(198, 190, 152);
}

.white.three {
	background: rgb(199, 199, 197);
}

.white.four {
	background: rgb(198, 182, 142);
}

.bordercolor span {
	font-size: 12px;
	font-family: "Conv_Proxima Nova Semibold";
	color: #006269;
	margin-right: 20px;
	line-height: 15px;
}

.bordercolor i img {
	max-width: 20px;
	margin-right: 10px;
}

.bordercolor span.selected {
	color: #868686;
	margin-left: 20px;
}

.bordercolor span.selected em {
	font-style: normal;
}

.imagesborder img {
	max-width: 100%;
	object-fit: contain;
	height: 100%;
	width: 100%;
}

#full-design-mobile .imagesborder {
	padding: 0;
}

.accessorySummary span.images.img-align {
	text-align: center;
}

.col-sm-12.colors {
	padding: 0 8px;
	margin: 10px 0;
}

/*Side Bar Images*/
.mainimages {
	position: fixed;
	bottom: 0;
	top: 0;
	right: 0;
	height: 100%;
	max-width: 60%;
}

.bottom.icons {
	bottom: 10px;
	right: 10px;
	position: fixed;
	width: 57%;
	cursor: pointer;
}

.bottomzoomicons {
	width: auto;
	position: absolute;
	bottom: 60px;
	background: #fff;
	text-align: center;
	border-radius: 3px;
	padding: 12px 9.5px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.bottomzoomicons li:last-child {
	margin: 0;
}

.bottomzoomicons li {
	text-align: center;
	list-style: none;
	margin: 0 0 12px;
}

.bottomzoomicons li img {
	max-width: 100%;
}

.iconsleft {
	position: absolute;
	bottom: 0px;
	background: #fff;
	padding: 5px 16px 5px;
	border-radius: 5px;
	left: 0;
	width: auto;
}

.iconsleft .imagesfull {
	margin: auto;
	float: left;
	width: 32px;
}

.iconsleft i.white {
	margin-right: 10px;
	top: 4px;
}
.iconsleft .white:last-child {
    margin-right: 0;
}

.rightsitems {
	position: fixed;
	bottom: 10px;
	right: 0;
}

.rightsitems .savebtn {
	background: #fff;
	margin: 0 10px 0 0;
	border-radius: 5px;
	padding: 10px 0;
	font-size: 13px;
	color: #2e2e2e;
	position: relative;
	top: -1px;
	font-family: "Conv_Proxima Nova Semibold";
	display: inline-block;
	text-decoration: none !important;
	min-width: 100px;
	text-align: center;
}

.rightsitems .savebtn  {
	font-style: normal;
	text-transform: uppercase;
	font-size: 14px;
	
}

.rightsitems .savebtn i {
	margin: 0 5px 0 0;
}

/*Bath Tub Center Images*/
.bathtub {
	/* bottom: -3px; */
	position: fixed;
	right: -12px;
	width: 61%;
}

.bathtub img {
	max-width: 100%;
	object-position: center center;
	object-fit: cover;
	width: 100%;
}

.bathtub img {
	max-width: 100%;
}

/*WallImage*/
.wallimage {
	bottom: -7px;
	position: fixed;
	right: -9px;
	width: 61%;
}

.wallimage img {
	max-width: 100%;
	object-position: center center;
	object-fit: cover;
	width: 100%;
}

/*faucet Image Css start here*/

.fauctimage {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 60%;
	z-index: 999999;
}

.fauctimage img {
	max-width: 100%;
}

/*shelve image Css start here*/
.shelveimage {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 60%;
}

.shelveimage img {
	max-width: 100%;
}

/*-----soap image css start here--------*/
.soapimage {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 60%;
}

.soapimage img {
	max-width: 100%;
}

/*---------sidebar image css satrt here-----*/
.sideBarMain {
	position: fixed;
	bottom: 35%;
	right: 27%;
	width: 6%;
}

.sideBarMain img {
	max-width: 100%;
}

/*------------sidebarMainHoriHigh css start here--------------*/
.sideBarMainHoriHigh {
	position: fixed;
	bottom: 38%;
	right: 27%;
	width: 6%;
}

.sideBarMainHoriHigh img {
	max-width: 100%;
}

/*------------sidebarMainHoriMid css start here--------------*/
.sideBarMainHoriMid {
	position: fixed;
	bottom: 33%;
	right: 27%;
	width: 6%;
}

.sideBarMainHoriMid img {
	max-width: 100%;
}

/*------------sidebarMainHoriLow css start here--------------*/
.sideBarMainHoriLow {
	position: fixed;
	bottom: 30%;
	right: 27%;
	width: 6%;
}

.sideBarMainHoriMid img {
	max-width: 100%;
}

/*------------sidebarMainVertMid css start here--------------*/
.sideBarMainVertMid {
	position: fixed;
	bottom: 36%;
	right: 27%;
	width: 6%;
}

.sideBarMainVertMid img {
	max-width: 100%;
}

/*------------sidebarMainVertHigh css start here--------------*/
.sideBarMainVertHigh {
	position: fixed;
	bottom: 39%;
	right: 27%;
	width: 6%;
}

.sideBarMainVertMid img {
	max-width: 100%;
}

/*------------sidebarMainVertLow css start here--------------*/
.sideBarMainVertLow {
	position: fixed;
	bottom: 34%;
	right: 27%;
	width: 6%;
}

.sideBarMainVertMid img {
	max-width: 100%;
}

/*-----door image css start here-------*/
.doorimage {
	bottom: -8px;
	position: fixed;
	right: 0;
	width: 60%;
	height: 100%;
	transform: scaleY(1.2);
}

.doorimage img {
	max-width: 100%;
}

/*----------rod image css start here----------*/
.rodimage {
	bottom: 0;
	position: fixed;
	right: 8px;
	width: 59%;
	z-index: 999999;
}

.rodimage img {
	max-width: 100%;
	object-position: center center;
	object-fit: cover;
	width: 100%;
}

/*------------css for accordion accessories-----*/
.accordion {
	border: none;
	border-radius: 0;
	width: 100%;
	float: left;
}

.accordion__item {
	border: none !important;
}

.accordion__item+.accordion__item {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
	background-color: #f3f3f3;
	border: none;
	cursor: pointer;
	padding: 9px 10px;
	text-align: left;
	width: 100%;
	float: left;
	font-size: 14px;
}

#tablescroll.tabsinner {
	max-height: 47vh;
	float: left;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.accordion__button:hover {
	background-color: #ddd;
}

.accordion__button:before {
	border-bottom: 2px solid;
	border-right: 2px solid;
	content: "";
	display: inline-block;
	height: 10px;
	margin-right: 12px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	width: 10px;
	right: 20px;
	position: absolute;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
	transform: rotate(45deg);
}

.accordion__heading {
	width: 100%;
	float: left;
	position: relative;
	margin: 7px 0;
}

.accordion__heading .accordion__button {
    background: none;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
}
.MainBar .col-sm-3 .imagesborder img {
	max-width: 100%;
}

[hidden] {
	display: none;
}

.summary.Links {
	position: absolute;
	right: 60px;
	top: 7px;
	z-index: 9;
}

.summary.Links a {
	font-size: 13px;
	color: #00696a;
	text-decoration: underline;
	position: relative;
	top: -3px;
}

.accordion__panel {
	-webkit-animation: fadein 0.35s ease-in;
	animation: fadein 0.35s ease-in;
	padding: 10px 0;
	width: 100%;
	float: left;
}

/*SUmmery Page CSS*/
.congrats {
	width: 100%;
	float: left;
	margin: auto;
	font-family: "Conv_Proxima Nova Bold";
	font-size: 26px;
	text-transform: uppercase;
	line-height: 20px;
	margin-bottom: 8px;
}

.congratssmalltex {
	width: 100%;
	margin: 0 0 21px;
	display: inline-block;
	font-size: 15px;
	line-height: 20px;
}

.filtertext.middletextcenter.margintop {
	margin-top: 10px;
}

.tablescrollOd {
	width: 100%;
	float: left;
	margin: auto;
}

.tablescrollOd .images {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 10px;
	width: 130px;
	height: 100px;
	line-height: 5;
	overflow: hidden;
	text-align: center;
}

.tablescrollOd .images img {
	max-width: 100%;
	max-height: 100%;
}

#tablescrollOd table td:nth-child(1) {
	min-width: 160px;
	max-width: 160px;
}

#tablescrollOd table td:nth-child(2) {
	min-width: 120px;
	max-width: 120px;
	font-size: 13px;
	/* font-family: 'Conv_FontsFree-Net-Proxima-Nova-Sbold'; */
	line-height: 16px;
}

#tablescrollOd table td:nth-child(3) {
	min-width: 120px;
	max-width: 120px;
	font-size: 11px;
	/* font-family: 'Conv_FontsFree-Net-Proxima-Nova-Sbold'; */
}

#tablescrollOd table td:nth-child(3) .colorboxes {
	display: inline-block;
	float: none;
	float: right;
	width: 70%;
	font-size: 13px;
	line-height: 16px;
}

.innergrid tr {
	padding: 8px;
	display: table-row;
}

.saveicons {
	float: left;
	font-size: 14px;
	margin: 10px 0 0;
	padding-bottom: 10px;
	width: 100%;
}

.bottomfixed {
	background: #fff;
	bottom: 0;
	padding: 0;
	position: relative;
	text-align: center;
	width: 100%;
	left: 0;
	z-index: 999;
	padding-bottom: 100px;
	float: left;
}

.saveicons.shareicons {
	color: #000;
	float: left;
	font-size: 14px;
	text-align: center;
}

.saveicons.shareicons span {
	width: auto;
	display: inline-block;
}

.saveicons.shareicons ul {
	display: inline-block;
	margin: auto;
	padding: 0;
	margin-left: 10px;
}

.saveicons.shareicons ul li {
	list-style: none;
	display: inline-block;
	margin: 0 8px;
	color: #000;
}

.saveicons.shareicons ul li a {
	color: #000;
}

.bottomfixed button.consult {
	font-size: 11px;
	padding: 10px 29px;
}

.saveicons.shareicons span {
	width: auto;
	display: inline-block;
	font-weight: bold;
}

.saveicons.shareicons ul {
	display: inline-block;
	margin: auto;
	padding: 0;
	margin-left: 10px;
}

.saveicons.shareicons ul li {
	list-style: none;
	display: inline-block;
	margin: 0 8px;
	color: #000;
}

.saveicons.shareicons ul li a {
	color: #000;
}

.saveicons ul {
	margin: 0;
	padding: 0;
}

.saveicons ul li {
	display: inline-block;
	list-style: none;
	margin: auto;
	width: 25%;
	float: left;
	font-size: 11px;
	font-family: Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold;
	text-decoration: underline;
	color: #006269 !important;
	text-align: center;
}

.bottomfixed button.consult {
	right: 0;
	float: none;
}

#tablescrollOd .filtertext.middletextcenter {
	background: no-repeat;
	text-align: center;
	padding: 10px 0 0;
}

.col-sm-5.addbutton {}

.col-sm-5.addbutton .clear {
	font-size: 13px;
	color: #2f2f2f !important;
}

#tablescrollOd table td {}

#tablescrollOd table td .images {
	float: left;
	/* width: 100%; */
	display: inline-block;
}

.saveicons ul li a {
	color: #006269 !important;
}

.saveicons ul li img {
	margin-right: 5px;
	max-width: 20px;
}

.saveicons.pdf ul li i img {
	max-width: 40px;
}

.saveicons ul.border li:last-child a {
	border: none;
}

.saveicons ul.border li a {
    color: #00696a;
    float: left;
    font-size: 14px;
    padding: 0 0;
    
    width: 100%;
    text-decoration: underline;
    position: relative;
 

}

ul.border.pdf li a {
	padding: 0 37px 0 0;
}

ul.border.pdf i {
	position: absolute;
	right: 0px;
	font-size: 20px;
}

.saveicons.pdf ul.border.pdf i img {
	position: relative;
	top: -11px;
	max-width: 35px;
}

ul.border.pdf li:last-child a {
	padding-right: 30px;
	padding-left: 20px;
}

ul.border.pdf li:last-child a i {
	top: -2px;
}

.summary.Links {
	position: absolute;
	right: 60px;
	top: 7px;
	z-index: 99;
}

.summary.Links a {
	font-size: 13px;
	color: #00696a;
	text-decoration: underline;
	position: relative;
	top: -3px;
}

.viewSummary {
	text-align: center;
	margin: 0 0 20px 0;
	float: left;
	width: 100%;
}

.viewSummary button.consult {
	float: none;
	padding: 10px 20px;
	font-size: 15px;
	background: #00696a;
	border: 1px solid #00696a;
	width: 100%;
	margin: 0;
	right: 0;
	border-radius: 5px;
	margin: 40px 0 0;
}

ul.border.pdf li:last-child a svg {
	margin-left: 7px;
}

ul.border.pdf li:last-child a svg {
	margin-left: 7px;
}

#WalLsimg .col-sm-4 .imagesborder {
	padding: 0;
}

#WalLsimg .col-sm-4 .imagesborder img {
	height: 100%;
}

.imagesborder svg {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #006269;
}

.col-sm-4 .imagesborder svg {
	position: absolute;
	right: 10px;
	top: 10px;
	color: #006269;
}

#WalLsimg .col-sm-4 .padding .imagesborder {
	padding: 0;
}

#WalLsimg .col-sm-4 .padding .imagesborder img {
	height: 100%;
	width: 100%;
}

#tablescrollOd .consult {
	float: none;
	margin: 0 0 35px;
}

#faucetDiv .imagesborder {
	height: 120px;
	padding: 0;
	line-height: 7;
}

#doormid .imagesborder img {
	width: 100%;
	height: 100%;
}

.col-sm-12.colors.desktopMobile .bordercolor {
	margin: 10px 0;
}

#accessDiv .col-sm-12.colors.desktopMobile .bordercolor .imagesborder {
	height: 80px;
	line-height: 5;
	padding: 6px;
	position: relative;
	width: 100%;
	float: left;
}

#accessDiv .col-sm-12.colors.desktopMobile .bordercolor .imagesborder svg {
	margin: 0 !important;
	z-index: 9;
}

.col-sm-12.colors.desktopMobile .orientationsBars span.col-sm-3 {
	margin: 0;
	width: 25%;
}

.col-sm-12.colors.desktopMobile .orientationsBars span.col-sm-3 .title {
	color: #2e2e2e;
	font-family: Conv_ProximaNova-Regular;
	font-size: 13px;
	margin: auto auto 5px;
	word-break: break-word;
}

.bottomPlacement .col-sm-12.colors .clear.orange {
	float: left;
	color: #2e2e2e !important;
	font-family: Conv_ProximaNova-Regular;
	font-size: 13px;
	/* margin: 0 15px; */
	word-break: break-word;
	text-decoration: none;
	padding: 5px 8px;
	border: 1px solid #40c1ac;
}

.bottomPlacement .col-sm-12.colors span {
	width: 100% !important;
	float: left;
	text-align: center;
	padding: 10px 0;
	margin: 0px 5px 0px -46px;
}

.bottomPlacement .bordercolor .clear.orange.active {
	background: #40c1ac;
}

/*---------------sidebar css start here---------*/
.MainBar .leftBar.col-sm-3 img {
	-webkit-transform: rotate(198deg);
	transform: rotate(198deg);
}

.MainBar .rightBar.col-sm-3 img {
	transform: rotate(116deg);
}

img#leftBar {
	-webkit-transform: rotate(198deg);
	transform: rotate(198deg);
}

.MainBar .horizontalBar.col-sm-3 img {
	-webkit-transform: rotate(154deg);
	transform: rotate(154deg);
}

.MainBar .verticalBar.col-sm-3 img {
	transform: rotate(245deg);
	object-fit: contain;
	height: 100%;
}

.bathHide {
	display: none;
}

img#vertBar {
	transform: rotate(245deg);
	bottom: 0%;
}

img#horiBar {
	-webkit-transform: rotate(154deg);
	transform: rotate(154deg);
}

#rightBar {
	transform: rotate(116deg);
}

div#scrollBath span.imagesborder {
	padding: 0;
	height: 120px;
}

div#tablescrollOd table {
	width: 100%;
	table-layout: fixed;
}

div#tablescrollOd table img#summaryBath {
	object-fit: contain;
	position: relative;
	width: 60%;
	margin: 10px;
	/* top: -50px; */
}

img#summaryWall {
	width: 60%;
	margin: 10px;
}

#tablescrollOd .filtertext {
	margin: 0;
}

.modal-dialog {
	position: absolute;
	left: 0;
	margin: 0;
	top: 0;
	right: 0;
	padding: 0;
	transform: translateY(-50%) !important;
	top: 50%;
	margin: auto;
}

.modal-header {
	padding: 0;
	border: none;
	margin: auto;
}

a.crox {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 9999;
	cursor: pointer;
}

.modal-body {
	display: inline-block;
	margin: auto;
	max-height: 406px;
	overflow: auto;
	padding-top: 9px;
	width: 100%;
	max-height: initial;
	padding: 50px;
}

.tutorial.modal {
    z-index: 99999999;
}
.modal-body.FormCss .col-sm-12 p {
	font-size: 16px;
	width: 100%;
	float: left;
	font-weight: 600;
	margin: 0 0px 10px;
}

.modal-body.FormCss form {
	text-align: center;
	margin-top: 10px;
	width: 100%;
	float: left;
}

.modal-body.FormCss .form-group {
	width: 100%;
	float: left;
}

.modal-body.FormCss .form-group label {
	float: left;
	width: 20%;
	text-align: center;
	position: relative;
	top: 6px;
}

.emailInerr {
	width: 80%;
	float: left;
	margin: auto;
	position: relative;
}

.modal-body.FormCss .form-group input.form-control {
	width: 100%;
	height: auto;
	padding: 7px 16px;
}

.modal-body.FormCss form input.consult {
	float: none;
	padding: 7px 27px;
	font-size: 15px;
}

div#exampleModal .modal.in .modal-dialog {
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	position: absolute;
	background: #fff;
	padding: 15px;
}

.modal-body.FormCss .col-sm-12 {
	text-align: center;
}

#Mobile {
	display: none;
}

/*Popup Css*/

.modal {
	background: rgba(0, 0, 0, 0.7);
	z-index: 999999;
}

.modal .modal-dialog {
	max-width: 35%;
	position: absolute;
	margin: auto;
	top: 50%;
	transform: translatey(-50%) !important;
	left: 0;
	right: 0;
}

.modal .modal-dialog.book-consultant{
	max-width: 45%;
}

.modal-header {
	padding: 15px 25px;
	border: none;
	width: 100%;
	float: left;
	margin: auto;
}

.modal-header .close {
	opacity: 1;
	position: absolute;
	right: 20px;
}

.modal-body {
	padding: 30px 20px;
	width: 100%;
	float: left;
	margin: auto;
}

.fullwidthsmidd {
	width: 100%;
	display: inline-block;
	float: left;
	margin: auto;
}

.modal-content {
	width: 100%;
	float: left;
	margin: auto;
}

.col-sm-12.fullform {
	width: 100%;
	float: left;
	margin: 0 0 17px;
	padding: 0 10px;
}

.col-sm-12.fullform:last-child {
	margin: 0;
}

.col-sm-12.fullform .form-control {
	height: auto;
	padding: 14px 10px;
	font-size: 12px;
	border: 1px solid #ccc;
	box-sizing: border-box;
	box-shadow: none;
	outline: none;
	color: #868686;
	border-radius: 0;
	font-family: "Conv_Proxima Nova Semibold";
}

button.btn.upload {
	background: #f5875f;
	border: 1px solid #f5875f;
	border-radius: 3px;
	color: #fff;
	float: right;
	font-family: "Conv_Proxima Nova Semibold";
	font-size: 14px;
	padding: 12px 11px;
	position: relative;
	right: 0;
	text-transform: uppercase;
	top: 0;
	width: 100%;
}

.center {
	text-align: center;
}

.consentText {
	font-size: 12px;
	color: #868686;
	text-align: justify;
	padding: 0px 10px;
  }

.rememberbox {
	display: inline-block;
	position: relative;
	padding-left: 28px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	color: #868686;
	user-select: none;
	font-family: "Conv_Proxima Nova Semibold";
}

.rememberbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
}

.rememberbox input:checked~.checkmark {
	background-color: #f5875f;
	border-color: #f5875f;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.rememberbox input:checked~.checkmark:after {
	display: block;
}

.rememberbox .checkmark:after {
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/*Start Over Popup*/
#WebSaveDesingId #exampleModalLabel {
	color: #2f2f2f;
	font-size: 16px;
	font-family: "Conv_Proxima Nova Semibold";
	text-transform: uppercase;
}

#exampleModal4 #exampleModalLabel {
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
}

#WebSaveDesingId #exampleModalLabel {
	font-size: 16px;
	border-bottom: 1px solid #ccc;
	padding: 21px 0 13.5px 0;
	line-height: 19px;
}

#exampleModal4 .modal-header {
	padding-bottom: 0;
}

.modal.WebSaveDesing .modal-body .col-sm-12.fullform:last-child {
	margin: 0;
}

#exampleModal4 .modal-body {
	padding: 20px 20px;
}

.startmid {
	width: 100%;
	float: left;
	margin: auto;
	color: #2f2f2f;
	font-size: 16px;
}

.startleftimages {
	float: left;
	width: 15%;
}

.starttextright {
	width: 80%;
	float: right;
	margin: auto;
}

#exampleModal4 .modal-dialog {
	max-width: 34%;
}

.savebuttonboxs {
	width: 100%;
	float: left;
	margin: 30px 0 0;
	text-align: center;
}

.savebuttonboxs .savebtn {
	background: no-repeat;
	border: 1px solid #007377;
	font-family: "Conv_Proxima Nova Semibold";
	width: 47%;
	font-size: 15px;
	color: #007377;
	padding: 10px 0;
	border-radius: 8px;
	text-transform: uppercase;
}

.savebuttonboxs .savebtn:last-child {
	float: right;
}

.savebuttonboxs .savebtn:first-child {
	float: left;
}

.savebtn.save {
	background: #007377;
	border-color: #007377;
	color: #fff;
}

/*Save This Design*/

#save {
	color: #2f2f2f;
	font-size: 18px;
	font-family: "Conv_Proxima Nova Semibold";
	text-transform: uppercase;
}

#save #exampleModalLabel {
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
}

#save .modal-header {
	padding-bottom: 0;
}

#save .modal-body {
	padding: 20px 20px;
}

.emailtexts {
	padding: 0 10px;
	font-size: 15px;
	width: 100%;
	float: left;
	margin: 0 0 20px;
}

#save .modal-dialog {
	max-width: 34%;
}

/*Summery Popup*/
#missings {
	color: #2f2f2f;
	font-size: 18px;
	font-family: "Conv_Proxima Nova Semibold";
	text-transform: uppercase;
}

#missings #exampleModalLabel {
	border-bottom: 1px solid #ccc;
	padding-bottom: 13.5px;
	font-size: 16px;
}

#missings .modal-header {
	border-radius: 0;
	padding: 30px 40px 13.5px;
}

#missings .modal-dialog {
	max-width: 520px;
	text-align: center;
	height: 330px;
}

#missings .modal-body {
	padding: 0px 40px 17px;
}

#missings .modal-body .emailtexts {
	margin: 0;
	font-size: 16px;
	line-height: 19px;
}

#missings .modal-fotter {
	background: #f4f9f9;
	width: 100%;
	float: left;
	padding: 23px 40px 30px 40px;
}

#missings .modal-fotter .emailtexts {
	color: #2f2f2f;
	font-size: 16px;
	font-family: "Conv_Proxima Nova Semibold";
	text-transform: uppercase;
}

.buttoncenters {
	width: 100%;
	float: left;
	margin: auto;
	/* text-align: left;
        padding: 0 30px; */
}

.summary {
	padding: 12px 30px;
	background: #ddeaeb;
	border: 2px solid #006269;
	font-family: "Conv_Proxima Nova Semibold";
	font-size: 18px;
	border-radius: 4px;
	color: #006269;
	width: 47%;
	text-align: left;
	position: relative;
}

.backtext {
	color: #006269;
	text-decoration: underline;
	margin: auto 70px auto auto;
	width: auto;
	display: inline-block;
	/* margin-right: 70px; */
	font-size: 14px;
	text-transform: none;
	font-family: "Conv_ProximaNova-Regular";
}

.fas.fa-chevron-right {
	position: absolute;
	right: 20px;
	top: 15px;
}

.summary:hover {
	background: #006269;
	color: #fff;
	border-color: #006269;
}

/*Share Popup*/
#share {
	color: #2f2f2f;
	font-size: 18px;
	font-family: "Conv_Proxima Nova Semibold";
}

#share #exampleModalLabel {
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
}

#share .modal-header {
	padding-bottom: 0;
}

.socialicons {
	float: left;
	margin: auto;
	width: 100%;
	padding: 0 10px;
	margin-bottom: 20px;
}

.socialicons li {
	list-style: none;
	display: inline-block;
	margin-right: 16px;
}

#share .modal-body {
	padding-top: 20px;
}

#share .emailtexts {
	font-size: 14px;
	margin-bottom: 10px;
}

.copywith {
	padding: 0 10px;
	float: left;
	width: 100%;
	position: relative;
}

.copywith img {
     position: absolute;
     top: 50%;
     left: 23px;
     transform: translateY(-50%);
    }

button.copy {
    background: #006269;
    border: none;
    color: #fff;
    font-size: 14px;
    outline: none;
    position: absolute;
    right: 5px;
    top: 50%;
    width: 68px;
    height: 33px;
    border-radius: 3px;
    line-height: 33px;
    transform: translateY(-50%);
}

.copywith .form-control {
	height: auto;
	border: 1px solid #ccc;
	padding: 9px 90px 9px 40px;
	color: #2f2f2f;
	box-shadow: none;
	outline: none;
}

.draggable {
	position: fixed;
}

.draggable-panel {
	cursor: move;
}

/*---------css for save pop desing for Web-----*/
.modal.WebSaveDesing {
	background: none !important;
	position: absolute;
	bottom: 7px !important;
	z-index: 999999 !important;
	right: -18px !important;
	left: auto !important;
	width: 40%;
}

.modal.WebSaveDesing.errors1 { 
	bottom: 35px !important;
	overflow: visible;
}

.modal.WebSaveDesing.errors2 { 
	bottom: 65px !important;
	overflow: visible;
}

.modal.WebSaveDesing.errors3 { 
	bottom: 95px !important;
	overflow: visible;
}

.modal.WebSaveDesing.errors4 { 
	bottom: 125px !important;
	overflow: visible;
}

.modal.WebSaveDesing .modal-dialog {
	position: absolute;
	transform: none !important;
	left: auto;
	max-width: 74% !important;
	bottom: 50px !important;
	top: auto !important;
	right: 100px;
}

.modal.WebSaveDesing .modal-dialog .modal-header {
	padding: 0px 20px;
}

.modal.WebSaveDesing .emailtexts {
	text-transform: none;
	line-height: 17px;
	font-size: 14px;
	margin: 0 0 13px;
	padding: 0;
}

.modal.WebSaveDesing .modal-dialog {
	width: 340px;
	height: 220px;
	bottom: 280px !important;
	border-radius: 3px;
}

.modal.WebSaveDesing .modal-body .col-sm-12.fullform .form-control {
	padding: 13px 10px;
	border-radius: 3px;
}

.modal.WebSaveDesing .modal-body {
	padding: 13px 20px 22px;
}

.modal.WebSaveDesing .modal-body .col-sm-12.fullform .form-control::placeholder {
	font-size: 14px;
	font-family: "Conv_ProximaNova-Regular";
	font-weight: 500;
}

.modal.WebSaveDesing .modal-body .col-sm-12.fullform .upload {
	height: 33px;
	line-height: 17px;
	font-size: 14px;
	border-radius: 3px;
	padding: unset;
}

.modal.WebSaveDesing .modal-body .col-sm-12.fullform {
	padding: 0;
	margin-bottom: 8px;
}

.modal.WebSaveDesing .modal-dialog .modal-content {
    border: none;
    border-radius: 3px;
}
/*-----------css for the social share desktop pop up-----------*/
.modal.WebShare {
    background: none!important;
    bottom: 24px!important;
    left: auto!important;
    position: absolute;
    right: 18px!important;
    width: 100%;
    z-index: 999999!important;
}

.modal.WebShare .modal-dialog {
    bottom: 40px!important;
    left: auto;
    width: 340px;
    position: absolute;
    right: 0px;
    top: auto!important;
    -webkit-transform: none!important;
    transform: none!important;
    /* height: 220px; */
}
.modal.WebShare .modal-body .copywith input {
    width: 300px;
    height: 45px;
}
.modal.WebShare .modal-body .copywith img {
     left: 13px;    
    }
.modal.WebShare .modal-dialog .modal-header {
    border-bottom: 1px solid #ccc;
    padding: 0 0 13.5px;
}
.modal.WebShare .modal-dialog .modal-header h5 {
    font-size: 16px;
    color: #2F2F2F;
    text-transform: uppercase;
    font-weight: 900;
}
.modal.WebShare .modal-body {
    padding: 13.5px 0;
}
.modal.WebShare .modal-body p.emailtexts {
    padding: 0;
    margin-bottom: 8px;
}
.modal.WebShare .modal-body .copywith {
    padding: 0;
}
.modal.WebShare .modal-body .socialicons {
    float: left;
    margin: 0 0 14px;
    padding: 0;
    width: 100%;
}
.modal.WebShare .modal-body .socialicons img {
    width: 100%;
    max-width: 30px;
}
.modal.WebShare .modal-dialog .modal-content {
    border: none;
    padding: 21px 20px 0;
}

.modal.WebShare .modal-dialog .modal-content {
	border: none;
}

/*---------alert main div message for web--------------*/
.alertMainDiv {
	position: fixed;
	top: 17%;
	right: 0;
	z-index: 999999;
	left: 0;
}

.midditemss.alert {
	background: #f8eaea;
	color: #d93c3c;
	border: 1px solid #d93c3c;
}

.midditemss.alertSuccess {
	background: #d7f3d9;
	color: #6aba6c;
	border: 1px solid #d7f3d9;
}

.midditemss {
    position: fixed; 
    padding: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    max-width: 243px;
    text-align: center; 
}

.msgText {
	display: table-cell;
	vertical-align: middle;
	padding: 3px;
}

.imagAlert {
	display: table-cell;
	vertical-align: middle;
	padding: 3px;
	width: 35px;
}

.cross {
	cursor: pointer;
	cursor: pointer;
	position: absolute;
	right: 8px;
	top: 15px;
}

/*--------css for page not found --------*/
.error-container {
	font-family: montserrat, sans-serif;
}

.big-text {
	font-size: 200px;
	font-weight: 900;
	font-family: sans-serif;
	background: url(./images/404.jpg) no-repeat;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: cover;
	background-position: center;
	margin-bottom: 40px;
	padding-top: 5%;
}

.small-text {
	font-family: montserrat, sans-serif;
	color: rgb(0, 0, 0);
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
}

.button {
	color: #fff;
	padding: 12px 36px;
	font-weight: 600;
	border: none;
	position: relative;
	font-family: "Raleway", sans-serif;
	display: inline-block;
	text-transform: uppercase;
	-webkit-border-radius: 90px;
	-moz-border-radius: 90px;
	border-radius: 90px;
	margin: 2px;
	margin-top: 2px;
	background: #006269;
	flex: 1 1 auto;
	text-decoration: none;
}

.button:hover,
.button:focus {
	color: #ffffff;
	background: #f5875f;
	-webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
	text-decoration: none;
}

/*-------loader css for desktop------*/
.loaderImg {
	position: fixed;
	left: 0;
	margin: auto;
	z-index: 9999999999;
	right: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.7);
	bottom: 0;
}

.loaderImg img {
	max-width: 5%;
	position: absolute;
	left: 40%;
	margin: auto;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

/*---------css to add the reset link in header menu ------*/
.navigation ul li.resetHeaderLink {
	float: right;
	right: 0;
	position: absolute;
	top: 0;
}

span#resetSpan {
	margin: 0 0 0 5px;
	text-decoration: underline;
	color: #006269;
	font-family: "Conv_ProximaNova-Regular";
}

p.emailtexts {
	text-transform: none;
}

/*css to add the modify link in summary page----*/
#tablescrollOd table td:nth-child(3) .colorboxesMain {
	display: inline-block;
	text-transform: none;
	float: right;
	width: 70%;
	cursor: pointer;
	text-decoration: underline;
	padding-top: 5px;
	font-size: 10px;
}

/*---------circle number-------*/
span.headerNumer {
	width: 20px;
	height: 20px;
	border: 1px solid #868686;
	display: inline-block;
	text-align: center;
	line-height: 20px;
	border-radius: 100%;
	font-style: normal;
	margin-right: 5px;
	font-size: 12px;
	font-family: "Conv_FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold";
}

.navigation ul li.active span.headerNumer {
	border-color: #fff;
}

.col-sm-5.addbutton .clearModify {
	font-size: 13px;
	color: #000 !important;
}

.col-sm-5.addbutton .clear.clearAsset {
	color: #fff !important;
}

/****CSS for hover-overaly on sidebar-images*****/

.hover-overlay {
	background: #00968814;
	/* height: 100%; */
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	/* top: 0; */
	transition: all 0.3s;
	width: 100%;
	z-index: 99999999;
	opacity: 0;
}

.hover-overlay svg {
	cursor: pointer;
	position: absolute;
	right: 5px;
	top: 5px;
}

.active .hoverMainParent:hover .hover-overlay {
	opacity: 1;
}

.col-sm-4 .padding .hoverMainParent {
	display: flex;
	height: 100%;
	position: relative;
	width: 100%;
}

.col-sm-4 .padding span.tittle {
	padding-top: 10px;
}

.hoverMainParent span.imagesborder {
	margin: 0;
}

/*iamge hover css start here*/
span.imagesborder {
	position: relative;
}

span.imagesborder::before {
	position: absolute;
	content: "";
	background: #00968814;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	transition: 0.3s all;
}

span.imagesborder:hover::before {
	opacity: 1;
}

.socialicons a img {
	width: 30px;
	height: 30px;
}

/*home page css*/
@import url("https://use.fontawesome.com/releases/v5.15.4/css/all.css");

iframe {
	height: 100vh;
}

.pre-config-bg {
	position: relative;
    height: 100vh;
}

.pre-config-info {
	position: absolute;
	top: 100px;
	background: #fff;
	padding: 30px;
	left: 50%;
	transform: translateX(-50%);
	width: 492px;
}

.pre-config-info h4 span {
	/* background: #006269; */
	width: 35px;
	height: 35px;
	display: inline-block;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	margin-left: 20px;
}

.pre-config-info h4 span i {
	color: #fff;
}

.pre-config-info p {
	margin: 0 0 18px !important;
	line-height: 22px;
}

.pre-config-info h2 {
	margin-top: 0;
	margin-bottom: 18px;
	font-family: "PT Serif";
	line-height: 36px;
}

.pre-config-info h4 {
	margin-bottom: 0;
	margin-top: 0;
	color: #006269;
	font-weight: 600;
}

.follow-steps {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/*project type page  css*/

.pre-config-main2 {
	margin: auto;
	width: 50%;
	display: block;
	text-align: center;
}

.pre-config-main2 .left-right-img {
	display: flex;
	justify-content: center;
}

.pre-config-main2 .left-right-img .left-image {
	margin-right: 15px;
	text-align: left;
}

.pre-config-main2 .left-right-img .right-image {
	margin-left: 15px;
	text-align: right;
	position: relative;
}

.pre-config-main2 .left-right-img p {
	margin: 0;
	padding-top: 17px;
	font-size: 16px;
	font-weight: 700;
	color: #505050;
	display: inline-block;
	transition: 0.1s all;
}

.hover-icon {
	position: absolute;
	top: 45%;
	left: 50%;
	border: 2px solid #868686;
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	transform: translate(-50%, -50%) rotate(180deg);
}

.hover-icon i {
	color: #868686;
	font-size: 14px;
}

.right-image .hover-icon i {
	transform: rotate(180deg);
}

.back-to {
	padding-top: 80px;
}

.back-to a {
	color: #006269;
	text-decoration: underline;
	font-size: 16px;
	font-weight: normal;
}

.back-to a i {
	padding-right: 5px;
}

.pre-config-main2 .design-exp {
	margin-top: 10px;
	margin-bottom: 35px;
}

.pre-config-main2 .left-right-img .left-image {
	position: relative;
	flex-basis: 33%;
}

.thumbnail-img {
	position: relative;
	transition: 0.3s all;
	cursor: pointer;
}

/****Page-4-css****/

.pre-config-main4 .left-right-img img {
	height: 100%;
	max-width: 100%;
	width: 100%;
	opacity: 0.4;
	transition: 0.3s all;
}

.pre-config-main4 .left-right-img .left-image:hover img {
	opacity: 1;
}

.pre-config-main4 h2 {
	margin-top: 0px;
	padding-top: 145px !important;
	margin-bottom: 15px;
	font-size: 22px;
	line-height: 30px;
}

.leftmenu.DesktopView .imagesfull {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

#headerLoader {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

#categoryLoader {
	height: 30px;
	margin: 35px;
	transform: translateY(-100%);
}

/*css for bottom footer on assets side*/
.satisfaction-check {
	border: 2px solid #006269;
	background: #e5eff0;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0 50px;
	transition: 0.2s all;
}

.satisfaction-check .summary-arrow img {
	max-width: 8px;
	width: 100%;
}

.design-satisfy h4 {
	color: #006269;
	font-weight: 700;
	font-size: 18px;
	font-family: "Proxima Nova";
	margin: 0;
	line-height: 30px;
}

.design-satisfy p {
	line-height: 20px;
	font-size: 16px;
	font-family: "Proxima Nova";
	margin: 0;
	color: #006269;
}

.satisfaction-check:hover h4,
.satisfaction-check:hover p {
	color: #fff;
}

.satifaction-outer:hover,
.satifaction-outer:focus {
	text-decoration: none !important;
	border: 0 !important;
}

.satisfaction-check:hover {
	background-color: #006269;
}

.satisfaction-check:hover .summary-arrow img {
	filter: invert(1);
}

.pre-config-main2.choose-showers {
	width: 70%;
}

.choose-showers img {
	opacity: 0.4;
	transition: 0.2s all;
}

.choose-showers h2 {
	margin: 30px 0;
	font-family: "PT Serif";
}

.choose-showers .left-image:hover img {
	opacity: 1;
}

.left-image:hover p,
.right-image:hover p {
	text-decoration: underline;
	text-decoration-color: #40c1ac;
	text-underline-offset: 5px;
	text-decoration-thickness: 2px;
}

a:hover,
a:focus {
	text-decoration: none;
}

.leftmenu .accordion {
	padding: 0 10px;
}

.leftmenu div#accordion__heading-a {
	background: #e5eff0;
	border: 0;
}

/*css to add space in header menu for check */
.maninav li a img {
	width: 100%;
	max-width: 19px !important;
	object-fit: contain;
	margin-right: 5px;
}

.backtext i {
	margin-right: 5px;
}

.backgroundImage {
	position: absolute;
	left: 15px;
	top: 13px;
}

.pre-config-main4 .left-right-img .backgroundImage img {
	opacity: 1;
	object-fit: cover;
}

.choose-showers .backgroundImage img {
	opacity: 1;
}

.pre-config-bg video {
	width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

/*****Login-css****/
.load-project {
    text-align: center;
    clear: both;
}
.load-project-inner h2 {
    font-family: PTSerif-Regular;
}
.skiptogetstarted a {
	color: #006269;
	font-size: 16px;
}

.load-project-inner .load-email-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    width: 406px;
}

.load-email-field input {
	height: auto;
    width: 100%;
    padding: 9.96px 10px;
    border-radius: 3px;
    border: 1px solid #ddd;
}

.load-email-field button {
    width: 100%;
    background: #f5875f;
    border: 0;
    color: #fff;
    padding: 12px 0;
    border-radius: 3px;
    font-size: 14px;
    text-transform: uppercase;
	margin-top: 10px;
}

/*****Saved-designs****/
.saved-designs {
	text-align: center;
	width: 55%;
	margin: 50px auto 0;
}

.saved-designs .left-right-img {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.saved-designs .left-right-img .left-image {
	text-align: left;
	padding: 10px;
	flex-basis: 32%;
	max-width: 30%;
}

/* Zoom-reset-css */

.bottomzoomicons a.tooltip,
.iconsleft .tooltip {
	position: relative;
	display: inline-block;
	color: #006080;
	text-align: center;
	opacity: 1;
}

.bottomzoomicons .tooltip .tooltiptext {
	visibility: hidden;
	position: absolute;
	width: max-content;
	background-color: #fff;
	color: #000;
	text-align: center;
	padding: 2px 0;
    padding-left: 6px;
    padding-right: 6px;
	border-radius: 0;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.6s;
	font-size: 15px;
	border: 1px solid #979797;
	height: 24px;
	box-sizing: border-box;
	line-height: 18px;
}

.iconsleft .tooltip .tooltiptext {
	visibility: hidden;
	position: absolute;
	width: max-content;
	background-color: #fff;
	color: #000;
	text-align: center;
	padding: 2px 0;
	padding-left: 6px;
    padding-right: 6px;
	border-radius: 0;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.6s;
	font-size: 15px;
	border: 1px solid #979797;
	height: 24px;
	box-sizing: border-box;
	line-height: 18px;
	top: 50%;
	transform: translateY(-50%);
	right: -114px;
}

.bottomzoomicons .tooltip:hover .tooltiptext,
.iconsleft .tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.bottomzoomicons .tooltip-right {
	top: 3px;
	left: 90%;
}

.bottom.icons .iconsleft .imagesfull {
	margin-right: 21px;
}

.bottomzoomicons .tooltip-right::after,
.iconsleft .tooltiptext::after {
	content: "";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent #fff transparent transparent;
}

.bottomzoomicons .tooltip-right::before,
.iconsleft .tooltiptext::before {
	content: "";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -6px;
	border-width: 6px;
	border-style: solid;
	border-color: transparent #979797 transparent transparent;
}

.bottomzoomicons li:nth-child(2) .tooltip .tooltiptext {
	width: max-content;
}

.bottomzoomicons li:last-child .tooltip .tooltiptext {
	width: max-content;
}

.rememberbox>a,
.rememberbox {
	color: #868686;
	font-weight: 300 !important;
	font-family: "Conv_ProximaNova-Regular";
}

.rememberbox>a {
	text-decoration: underline;
	text-decoration-color: #707070;
	text-underline-offset: 2px;
}

.back-to.back-to-summary {
	padding-top: 21px;
}

.back-to.back-to-summary a {
	font-size: 14px;
}

.summary-btn {
	box-sizing: border-box;
	background: none;
	border: 1px solid #006269;
	color: #006269;
	font-size: 14px;
	width: 102px;
	height: 38px;
	border-radius: 3px;
	margin-left: 20px;
}

.summary-btn.active {
	background: #006269;
	color: #fff;
}

.summaryMenu {
	top: 50%;
	position: absolute;
	transform: translateY(-50%);
}

.book-consultation button.btn.upload {
	margin-top: 10px;
}

.book-consultation .col-sm-12.fullform {
	margin: 0 0 10px;
}

.book-consultation .modal-content {
	border-bottom: none;
}

.book-consultation .col-sm-12.fullform.custom-checkbox {
	margin: 10px 0 0;
}

#savePopSave .modal-content {
	padding: 19px 20px 22px;
	border-radius: 3px;
}

#savePopSave .modal-content .modal-header {
	padding: 0;
}

#savePopSave .modal-content .modal-header h5 {
	border-bottom: 1px solid #ccc;
	padding-bottom: 12px;
}

#savePopSave .modal-body {
	padding: 11.5px 0 0;
}

#savePopSave .modal-body p.emailtexts {
	padding: 0;
	font-size: 14px;
	line-height: 19px;
	margin: 0 0 10px;
}

#savePopSave .modal-body .col-sm-12.fullform {
	margin-bottom: 8px;
	padding: 0;
}

#savePopSave #exampleModalLabel {
    line-height: 21px;
    font-weight: 700;
    color: #2f2f2f;
    font-size: 16px;
    font-family: "Conv_Proxima Nova Semibold";
    text-transform: uppercase;
}

#savePopSave .modal-header .close {
    opacity: 1;
    position: absolute;
    right: 17px;
    top: 18px;
    margin-top: 0;
}

.tutorial .modal-dialog {
	width: 681px;
	height: 361px;
	box-sizing: border-box;
}

.tutorial .modal-dialog .modal-body {
	padding: 7px;
}

.tutorial .modal-dialog .modal-body span.close {
	opacity: 1;
	position: absolute;
	right: 14px;
	top: 14px;
}

.tutorial .modal-dialog .modal-content {
	background: none;
	border: 0;
	box-shadow: none;
}

.tutorial .modal-dialog .modal-content .modal-body {
	background: #fff;
}

.tutorial .modal-dialog .modal-content .modal-footer .goto-step {
	background: #fff;
	width: 100%;
	text-align: left;
	padding: 10px 0 10px 15px;
}

.tutorial .modal-dialog .modal-content .modal-footer {
	padding: 0;
}

.tutorial .modal-dialog .modal-content .skip-tutorial {
	text-align: center;
	display: block;
	padding-top: 13px;
	color: #fff;
	text-decoration: underline;
	text-underline-offset: 2px;
	font-size: 16px;
}

.tutorial .modal-dialog .modal-content .next-step {
	position: relative;
	bottom: 105px;
	right: 25px;
}

.tutorial .modal-dialog .modal-content .modal-footer .goto-step a img {
	width: 11px;
	height: 11px;
	opacity: 0.4;
}

.tutorial .modal-dialog .modal-content .modal-footer .goto-step a:nth-child(2) {
	padding: 0 15px;
}

.tutorial .modal-dialog .modal-content .modal-footer .goto-step a.active img {
	opacity: 1;
}

.headerbg .consult {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.headerbgFixed .consult {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.load-project-inner .load-email-field form {
	width: 100%;
}

.load-project-inner .instructions {
    margin: 0 0 37px;
    font-size: 18px;
}

.load-project-inner .load-email-field form small p {
	margin-bottom: 10px;
}

.load-email-field input:focus {
	outline: 0;
}

.DesktopView .consult {
	font-size: 14px;
	line-height: 18px;
	width: 238px;
	height: 44px;
}

#tablescrollOd table td:nth-child(2) span {
	font-size: 15px;
	line-height: 18px;
}

small.text-muted {
	text-align: left;
	color: red;
}

.center small.text-muted {
	text-align: center;
}

input.form-control.phone {
	position: absolute;
	z-index: 11;
	top: 0;
	right: 10px;
	width: 80%;
}

.country-code {
	position: relative;
}

.country-code .ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 15%;
    font-size: 11px !important;
    color: #868686;
    font-weight: bold;
    padding: 11px 5px 11px 0 !important;
    border-radius: 0;
}

.country-code {
	position: relative;
}

.country-code .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after,
.country-code .ReactFlagsSelect-module_selectBtn__19wW7:after {
	margin-left: -10px;
}
.thumbnail-img img {
    opacity: 0.4;
    transition: 0.2s all;
}

.left-image :hover img , .right-image :hover img {
    opacity: 1;
}
.pre-config-main2 {
    clear: both;
}
#full-design-mobile .imagesborder img {
    object-fit: cover;
    width: 100%;
}
.alertMainDiv .cross {
	right: -10px;
	top: -13px;
	background: #f8eaea;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 50%;
	line-height: 30px;
	box-shadow: 0 0 13px 0 #00000042;
}
.bathTubSummary .white {
    top: 8px;
}
div#savePopSave .modal-dialog {
    width: 340px;
}
.modal-title {
    font-size: 16px;    
     font-weight: 700;    
    text-transform: uppercase;    
    color: #2f2f2f;
    
 }
 .open ul.dropdown-menu.frenchLi {
    width: 270px;
}
.rightsitems .savebtn.frenchShare {
    min-width: 130px;
}
.headerfixed header.headerbg .consult.frenchBtn , .headerfixed header.headerbg .consult.spanishBtn  {
    width: 108px;
    line-height: 13px;
    padding: 0;
}
.savePopSavenew #exampleModalLabel {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 11px;
	text-align: center;
}
.savePopSavenew .modal-body {
    padding-top: 21px !important;
}
.savePopSavenew .modal-dialog {
    height: 133px;
}
.savePopSavenew .modal-body .savebuttonboxs {
    margin: 0;
	display: flex;
    justify-content: center;
    align-items: center;
}
.savePopSavenew .modal-content {
    padding: 21px 18px 26px !important;
}
.savePopSavenew .modal-body .savebuttonboxs a:first-child button {
    margin-right: 12px;
}
.savePopSavenew .modal-body .savebuttonboxs a:last-child button {
    width: 156px !important;
    height: 36px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-radius: 3px;
}
.savePopSavenew .modal-body .savebuttonboxs a button img {
    position: relative;
    top: -1px;
    margin-left: 13px;
}
.savePopSavenew .modal-body .savebuttonboxs a:first-child button {
    margin-right: 12px;
    width: 132px;
    height: 36px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3px;
}
.savePopSavenew .modal-header .close {
    right: 8px;
    top: 5px;
}
.fbSocial.activeSocialShare {display: block;}
.modal-body.emailShareActive {display: block;}
.modal-body.emailShare {display: none;}
.fbSocial{display: none;}
.modal.WebShare .modal-dialog .fullform {
    padding: 0;
    margin-bottom: 5px;
	width: 100%;
}
.emailShare {
    display: none;
}
.modal.WebShare .modal-body .emailShareActive {
    padding: 0;
}
.modal.WebShare .modal-dialog .fullform p {
    margin: 0;
}
.modal.WebShare .modal-dialog .fullform button.btn.upload {
    padding: 8px 11px;
}
.emailShareActive form p {
    font-weight: 700;
}
.modal-body .socialicons li {
    opacity: 0.25;
}
.modal-body .socialicons .socialLiActive{
    opacity: 1;
}

.product.index0, .productMob.index0 { z-index: 2; }
.product.index1, .productMob.index1 { z-index: 1; }
.product.index2, .productMob.index2 { z-index: 4; }
.product.index3, .productMob.index3 { z-index: 3; }
.product.indexgrabBar, .productMob.indexgrabBar { z-index: 5; }
.product.index4, .productMob.index4 { z-index: 6; }
.product.index5, .productMob.index5 { z-index: 7; }
.product.index6, .productMob.index6 { z-index: 8; }

.loaderImgleft img {
    max-width: 7%;
    margin: auto;
}

.loaderImgleft {
    text-align: center;
}
button.savebtn.LangBtn {
    line-height: 10px!important;
    font-size: 12px !important;
}

.nextStepIcon {
	background: #40c1ac;
	  width: 54px;
	  height: 54px;
	  border-radius: 100%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
}

.fullScreenLoader {
	position: fixed;
	left: 0;
	z-index: 9999999999;
	right: 0;
	top: 0;
	right: 0;
	text-align: center;
	background: rgba(0, 0, 0, 0.7);
	bottom: 0;
	height: 100%;
	margin: unset;
}

.fullScreenLoader img {
	max-width: 7%;
	position: absolute;
	left: 0;
	margin: auto;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

#my-node .loaderImg img {
	top: 55%;
	transform: translateY(-55%);
}